import React from "react";
import Grid from "@mui/material/Grid";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  IconButton,
} from "@mui/material";
import { QRCodeSVG } from "qrcode.react";
import ReplenishmentVM from "../viewmodel/ReplenishmentVM";
import CText from "../../../../basecomp/components/CText";

const styles = {
  table: {
    border: "1px solid #fff",
    borderCollapse: "collapse",
    minWidth: "100%",
    boxSizing: "border-box",
  },
  tableCell: {
    textAlign: "center",
  },
};

const Replenishment = (props) => {
  const {
    jobDirective,
    equipmentID,
    equipmentType,
    spDetails,
    jobCardNumber,
    handleToastOpen,
    jobType,
  } = props;
  const { mrpType, lotSize, handleIconButtonClick, isLoading } =
    ReplenishmentVM({
      spDetails,
      jobDirective,
      equipmentID,
      jobCardNumber,
      equipmentType,
      handleToastOpen,
      jobType,
    });

  return (
    <Grid container>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Grid item xs={12} sm={12} md={12} margin={1.5}>
        <CText cVariant="header_card" cText="Replenishment" />
      </Grid>

      <Grid
        xs={12}
        sm={12}
        md={12}
        style={{ overflowX: "auto", margin: "0 .7em" }}
      >
        {spDetails && spDetails.length > 0 ? (
          <Table sx={styles.table} id="replenishment_table">
            <TableHead
              sx={{
                backgroundColor: "#052660",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                "& .MuiTableRow-root": {
                  height: "48px", // Custom header row height
                },
                "& .MuiTableCell-root": {
                  padding: "0 16px", // Minimal padding for header cells
                },
              }}
            >
              <TableRow>
                <Tooltip title="SAP material number">
                  <TableCell sx={{ color: "#fff", textAlign: "left" }}>
                    Material
                  </TableCell>
                </Tooltip>
                <Tooltip title="SAP material description">
                  <TableCell sx={{ color: "#fff", textAlign: "left" }}>
                    Description
                  </TableCell>
                </Tooltip>
                <Tooltip title="Quantity requested in shopping basket">
                  <TableCell sx={{ color: "#fff", textAlign: "center" }}>
                    QTY
                  </TableCell>
                </Tooltip>
                <Tooltip title="On Hand Quantity during last monthly data refresh">
                  <TableCell sx={{ color: "#fff", textAlign: "center" }}>
                    OHQ
                  </TableCell>
                </Tooltip>
                <Tooltip
                  title={mrpType.split("\n").map((line, index) => (
                    <React.Fragment key={index}>
                      {line}
                      <br />
                    </React.Fragment>
                  ))}
                >
                  <TableCell sx={{ color: "#fff", textAlign: "center" }}>
                    MRP Type
                  </TableCell>
                </Tooltip>
                <Tooltip
                  title={lotSize.split("\n").map((line, index) => (
                    <React.Fragment key={index}>
                      {line}
                      <br />
                    </React.Fragment>
                  ))}
                >
                  <TableCell sx={{ color: "#fff", textAlign: "center" }}>
                    Lot Size
                  </TableCell>
                </Tooltip>
                <Tooltip title="SAP MRP triggers if On Hand QTY is below ROP (exception: if ROP is 0, it triggers at 0)">
                  <TableCell sx={{ color: "#fff", textAlign: "center" }}>
                    Re-order Point
                  </TableCell>
                </Tooltip>
                <Tooltip title="SAP MRP replenishes to the maximum stock level">
                  <TableCell sx={{ color: "#fff", textAlign: "center" }}>
                    Maximum Stock Level
                  </TableCell>
                </Tooltip>
                <Tooltip title="SAP MRP replenishes with a fixed lot size Quantity">
                  <TableCell sx={{ color: "#fff", textAlign: "center" }}>
                    Fixed Lot Size
                  </TableCell>
                </Tooltip>
                <Tooltip title="Unit of Measure">
                  <TableCell sx={{ color: "#fff", textAlign: "center" }}>
                    UOM
                  </TableCell>
                </Tooltip>
                <Tooltip title="Quick Response Code containing the SAP material number">
                  <TableCell sx={{ color: "#fff", textAlign: "center" }}>
                    QR code
                  </TableCell>
                </Tooltip>
              </TableRow>
            </TableHead>
            <TableBody
              sx={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                "& .MuiTableRow-root": {
                  height: "45px",
                },
                "& .MuiTableCell-root": {
                  padding: "2px 16px",
                },
              }}
            >
              {spDetails &&
                spDetails
                  .filter((data) => data.material_no)
                  .map((data) => (
                    <TableRow key={data.id}>
                      <TableCell sx={{ textAlign: "left" }}>
                        {data.material_no}
                      </TableCell>
                      <TableCell sx={{ textAlign: "left" }}>
                        {data.material_desc}
                      </TableCell>
                      <TableCell sx={styles.tableCell}>
                        {data.quantity}
                      </TableCell>
                      <TableCell
                        sx={styles.tableCell}
                        style={{
                          backgroundColor:
                            parseInt(data.ohqty) === 0
                              ? "#FFCCCB" // Red color when the value is zero
                              : parseInt(data.ohqty) >= parseInt(data.quantity)
                              ? "#90EE90"
                              : "#E0E0E0",
                        }}
                      >
                        {data.ohqty}
                      </TableCell>
                      <TableCell
                        sx={styles.tableCell}
                        style={{
                          backgroundColor:
                            data.type === "VB" ? "#90EE90" : "#E0E0E0",
                        }}
                      >
                        {data.type}
                      </TableCell>
                      <TableCell sx={styles.tableCell}>{data.ls}</TableCell>
                      <TableCell sx={styles.tableCell}>
                        {data.reorder_pt}
                      </TableCell>
                      <TableCell sx={styles.tableCell}>
                        {data.max_level}
                      </TableCell>
                      <TableCell sx={styles.tableCell}>
                        {data.fix_lot_size}
                      </TableCell>
                      <TableCell sx={styles.tableCell}>{data.uom}</TableCell>
                      <TableCell sx={styles.tableCell}>
                        <QRCodeSVG value={data.material_no} size={40} />
                      </TableCell>
                    </TableRow>
                  ))}
            </TableBody>
          </Table>
        ) : (
          ""
        )}
      </Grid>

      <Grid container>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            margin: ".7em",
          }}
        >
          <div style={{ display: "flex", marginTop: ".8em" }}>
            <Tooltip title="Replenishment Email">
              <IconButton
                id="email_replenishment"
                style={{
                  backgroundColor:
                    spDetails &&
                    spDetails.material !== null &&
                    spDetails.material !== "" &&
                    spDetails &&
                    spDetails.length === 0
                      ? "#E0E0E0"
                      : "#052660",
                  color: "#FFFFFF",
                  borderRadius: 5,
                  padding: "10px",
                  pointerEvents:
                    spDetails &&
                    spDetails.material !== null &&
                    spDetails.material !== "" &&
                    spDetails &&
                    spDetails.length === 0
                      ? "none"
                      : "auto",
                }}
                onClick={handleIconButtonClick}
              >
                <MailOutlineIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Replenishment;
