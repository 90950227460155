import React, { useState } from "react";
import Drawer from "../main/Drawer";
import {
  Grid,
  Typography,
  AppBar,
  Avatar,
  Popover,
  Box,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";
import PersonIcon from "@mui/icons-material/Person";
import MapsHomeWorkIcon from "@mui/icons-material/MapsHomeWork";
import { themeMain } from "../components/wrapper/CThemeProvider";

const Header = ({
  cLogo,
  cLogoSmall,
  handleLogout,
  cUserName,
  cUserInitials,
  pageTitle,
  appRouteMap,
  drawerList,
  pathName,
  navigate,
  linkComponent,
}) => {
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down("sm"));
  const terminal_id = localStorage.getItem("terminal");
  const terminal_name = localStorage.getItem("terminal_name");
  const [profilePopover, setProfilePopover] = useState(null);

  const handlePopOpen = (event) => {
    setProfilePopover(event.currentTarget);
  };

  const handlePopClose = () => {
    setProfilePopover(null);
  };

  return (
    <AppBar
      sx={{
        backgroundColor: themeMain.palette.mainbg.main,
        padding: "0.5em 1em",
      }}
    >
      <Grid container alignItems="center">
        <Grid item xs={1.5} sm={1} md={1} lg={0.5} xl={0.5}>
          <Drawer
            appRouteMap={appRouteMap}
            drawerList={drawerList}
            pathName={pathName}
            linkComponent={linkComponent}
          />
        </Grid>
        <Grid item xs={1} sm={2.5} md={2} lg={2} xl={1.5}>
          <Box
            component="img"
            src={isXs ? cLogoSmall : cLogo}
            alt="Click to see All Services"
            onClick={() => navigate("/services")}
            sx={{
              maxWidth: "80%",
              maxHeight: "100%",
              cursor: "pointer",
              objectFit: "contain",
              height: "1.6em",
              width: "auto",
            }}
          />
        </Grid>
        <Grid
          item
          xs={7}
          sm={5}
          md={6}
          lg={6.5}
          xl={7}
          sx={{ textAlign: "center", overflow: "hidden" }}
        >
          <Typography
            color="secondary"
            fontSize={isXs ? "1.5rem" : "1.8rem"}
            whiteSpace="nowrap"
            textOverflow="ellipsis"
          >
            {pageTitle}
          </Typography>
        </Grid>
        <Grid
          item
          xs={2.5}
          sm={3.5}
          md={3}
          lg={3}
          xl={3}
          sx={{ display: "flex", justifyContent: "flex-end" }}
        >
          {cUserName && (
            <div>
              <Avatar
                sx={{
                  padding: "0.2em",
                  bgcolor: themeMain.palette.secondary.main,
                  fontWeight: "bold",
                  cursor: "pointer",
                  "&:hover": {
                    backgroundColor: themeMain.palette.secondary.dark,
                  },
                  width: "1.6em",
                  height: "1.6em",
                }}
                onClick={handlePopOpen}
              >
                {cUserInitials}
              </Avatar>

              <Popover
                sx={{ marginTop: "0.5em" }}
                open={Boolean(profilePopover)}
                anchorEl={profilePopover}
                onClose={handlePopClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
              >
                <Box sx={{ padding: "1.5em", width: "170px" }}>
                  <Box display="flex" alignItems="center" mb={2}>
                    <PersonIcon
                      sx={{ marginRight: "0.5em",color: "#052660" }}
                      fontSize="small"
                    />
                    <Typography variant="subtitle1">
                      {cUserName}
                    </Typography>
                  </Box>

                  <Box display="flex" alignItems="center" mb={2}>
                    <MapsHomeWorkIcon
                      sx={{ marginRight: "0.5em",color: "#052660" }}
                      fontSize="small"
                    />
                    <Typography variant="subtitle1">
                      {terminal_name} - {terminal_id}
                    </Typography>
                  </Box>

                  <Box
                    display="flex"
                    alignItems="center"
                    mt={3}
                    onClick={handleLogout}
                    sx={{ cursor: "pointer" }}
                  >
                    <LogoutIcon
                      fontSize="small"
                      style={{
                        // backgroundColor: "#052660",
                        // color: "#FFFFFF",
                        color: "#052660",

                        borderRadius: 5,
                        paddingRight: "10px",
                        // marginRight: "5px",
                      }}
                    />
                    <Typography
                      variant="button"
                      sx={{
                        cursor: "pointer",
                        fontWeight: "bold",
                        color: "#052660",
                        "&:hover": { textDecoration: "underline" },
                      }}
                    >
                      Logout
                    </Typography>
                  </Box>
                </Box>
              </Popover>
            </div>
          )}
        </Grid>
      </Grid>
    </AppBar>
  );
};

export default Header;
