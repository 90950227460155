import { useState, useEffect } from "react";
import {
  fetchJobDetails,
  getSpBasketID,
  getSq01All,
  postSpBasket,
  pdfDownload,
  getGlobalById,
} from "../model/HistoricalJobDetailsModel";
import { useToast } from "../../../../basecomp/components/provider/ToastProvider";

import { getGlobalAndSpBasket } from "../model/HistoricalJobDetailsModel";

import shoppingBasketVM from "../../sp_basket/viewmodel/ShoppingBasketVM";

const HistoricalJobDetailsVM = (id) => {
  const { jobCardNumber } = shoppingBasketVM();

  const [jobDetails, setJobDetails] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [modalOpen, setModalOpen] = useState(false);
  const [error, setError] = useState("");
  const [quantity, setQuantity] = useState(1);
  const [spBasketId, setSpBasketId] = useState("");
  const [spDetails, setSpDetails] = useState([]);
  const [clickedData, setClickedData] = useState(null);
  const [selectedWorkOrderId, setSelectedWorkOrderId] = useState(null);
  const [clickedImageIndex, setClickedImageIndex] = useState(0);
  const [sliderImages, setSliderImages] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [openCarousel, setOpenCarousel] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { handleToastOpen } = useToast();

  //D-Catalogue Job Catalogue Details
  useEffect(() => {
    const fetchJobDetailsData = async () => {
      try {
        const data = await fetchJobDetails(id);
        setJobDetails(data[0]);
      } catch (error) {
        console.error("Error fetching job details:", error);
      }
    };
    fetchJobDetailsData();
  }, [id]);
  const [materialNumbers, setMaterialNumbers] = useState([]);
  //D-Catalogue Work Order Details
  useEffect(() => {
    const fetchSpDetailsData = async () => {
      setIsLoading(true);
      try {
        const data1 = await getGlobalAndSpBasket(id);
        
        // Extracting material_no values and filtering out blank or null entries
        const materials = data1
          .map((item) => item.material_no)
          .filter((materialNo) => materialNo && materialNo.trim() !== "");
        setMaterialNumbers(materials);

        // Extracting global_material_no where material_no is blank, empty, or null
        const globalMaterials = data1
          .filter((item) => !item.material_no || item.material_no.trim() === "")
          .map((item) => item.global_material_no)
          .filter(
            (globalMaterialNo) =>
              globalMaterialNo && globalMaterialNo.trim() !== ""
          );

        // Storing the filtered material numbers in state
        const globalMatData = await getGlobalById(globalMaterials);
        if (globalMatData.length > 0) {
          // Updating data1 with material_no and description from globalMatData
          const updatedData = data1.map((item) => {
            if (!item.material_no || item.material_no.trim() === "") {
              const globalMaterial = globalMatData.find(
                (globalItem) =>
                  globalItem.gbl_material_number === item.global_material_no
              );
              if (globalMaterial) {
                return {
                  ...item,
                  material_no: globalMaterial.material_no,
                  global_material_desc: globalMaterial.gbl_material_desc,
                };
              }
            }
            return item;
          });
          setSpDetails(updatedData);
        }else{

          setSpDetails(data1);
        }
      } catch (error) {
        console.error("Error fetching job details:", error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchSpDetailsData();
  }, [id]);

  //Historical Detail : Check quantity should be numeric
  const handleQuantityChange = (e) => {
    const inputValue = e.target.value;

    // Numeric regex pattern
    const numericPattern = /^[0-9]*$/;

    if (numericPattern.test(inputValue)) {
      setQuantity(inputValue);
      setError("");
    } else {
      setQuantity("");
      setError("Value must be numeric");
    }
  };

  //Historical Detail : Generate new sp_basket id logic (Job Id Catalogue)
  const fetchSpBasketID = async () => {
    try {
      const response = await getSpBasketID();
      const data = await response.data;

      if (data) {
        setSpBasketId(data.sp_basket_id);
      } else {
        console.error(data.error);
      }
    } catch (error) {
      console.error("Error occurred during API request:", error);
    }
  };
  useEffect(() => {
    fetchSpBasketID();
  }, []);

  const onButtonClick = async () => {
    try {
      const type = "work_order";
      const jobId = jobDetails.id;
      const response = await pdfDownload(jobId, type);
      const currentDate = new Date();
      const formattedDate = currentDate
        .toISOString()
        .slice(0, 10)
        .replace(/-/g, "_");
      const currentTime = currentDate.toLocaleTimeString();
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `Work_Order_Request_${formattedDate} ${currentTime}.pdf`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error fetching job details:", error);
    }
  };

  //Historical Job : Get all Sq01 details for filtering table (SQ01) && Insert or Add data in sp_basket (Sp Basket)
  const handleAddToCart = async () => {
    setIsLoading(true);
    try {
      const spData = spDetails;

      const sq01AllData = await getSq01All(materialNumbers); // Assuming this retrieves sq01AllData

      const result = spData.map((data, index) => {
        // Add an index parameter
        const materialNoToSearch = data.material_no;
        const terminalToSearch = data.terminal_id;

        // Find a matching entry in sq01AllData based on material and plant_id
        const Sq01Data = sq01AllData.find(
          (sq01) =>
            sq01.material_no == materialNoToSearch &&
            sq01.plant_id == terminalToSearch
        );

        if (Sq01Data) {
          const completeData = {
            ...data, // Copy data from spData
            ...Sq01Data, // Spread all fields from completeData
          };

          // You found a matching entry, now you can process it
          const qty_val = completeData.ohqty - data.quantity;
          const status_qty = qty_val >= 0 ? 1 : 0;
          const storage_bin_loc =
            completeData.storage_loc +
            (completeData.bin_location === null
              ? ""
              : " / " + completeData.bin_location);

          // Generate a new spBasketId for each row by appending the index
          const newSpBasketId = spBasketId + index;
          return {
            id: newSpBasketId, // Use the new spBasketId
            oem_no: completeData.oem_no || "",
            oem_description: completeData.oem_description || "",
            notes: completeData.notes || "",
            quantity: completeData.quantity || "",
            uom: completeData.b_un || "",
            material_no: completeData.material_no || "",
            storage_bin: storage_bin_loc || "",
            status: status_qty,
            job_id: jobCardNumber || "",
            type: "SB" || "",
            global_material_no: completeData.global_material_no || "",
            global_material_desc: completeData.global_material_desc || "",
          };
        } else {
          // Generate a new spBasketId for each row by appending the index
          const newSpBasketId = spBasketId + index;
          return {
            id: newSpBasketId, // Use the new spBasketId
            oem_no: data.oem_no || "",
            oem_description: data.oem_description || "",
            notes: data.notes || "",
            quantity: data.quantity || "",
            uom: data.b_un || "",
            material_no: data.material_no || "",
            storage_bin: data.storage_bin || "",
            status: data.status || 0, // You can set a default value here
            job_id: jobCardNumber || "",
            type: "SB" || "",
            global_material_no: data.global_material_no || "",
            global_material_desc: data.global_material_desc || "",
          };
        }
      });

      if (result) {
        const response = await postSpBasket(result, handleToastOpen);

        setModalOpen(false);
        if (response.status === 201) {
          setModalOpen(false);
          handleToastOpen("success", "Data added successfully!");
          fetchSpBasketID();
        }
      } else {
      }
    } catch (error) {
    } finally {
      setIsLoading(false); // Set isLoading to false after try-catch block execution
    }
  };

  //Historical Job : Get all Sq01 details for filtering table (SQ01)
  const handleSaveData = async () => {
    setIsLoading(true);
    try {
      if (quantity <= 0) {
        handleToastOpen("error", "Quantity must be greater than 0");
        return; // Exit function if quantity is not valid
      }
      const data = clickedData;
      const newQuantity = quantity;
      const sq01AllData = await getSq01All(materialNumbers);

      const materialNoToSearch = data.material_no;
      const terminalToSearch = data.terminal_id;

      // Find a matching entry in Sq01Data based on material and plant_id
      const Sq01Data = sq01AllData.find(
        (sq01) =>
          sq01.material_no == materialNoToSearch &&
          sq01.plant_id == terminalToSearch
      );

      let results = []; // Initialize an empty array to store multiple results

      if (Sq01Data) {
        // Merge the data from sq01AllData and clickedData
        const completeData = {
          ...Sq01Data, // Data from sq01AllData
          ...data, // Data from clickedData
        };

        const qty_val = completeData.ohqty - newQuantity; // Use newQuantity here
        const status_qty = qty_val >= 0 ? 1 : 0;
        const storage_bin_loc =
          completeData.storage_loc +
          (completeData.bin_location === null
            ? ""
            : " / " + completeData.bin_location);

        const spId = spBasketId;
        const result = {
          id: spId,
          oem_no: data.oem_no || "",
          oem_description: data.oem_description || "",
          notes: data.notes || "",
          quantity: newQuantity || "",
          uom: completeData.b_un || "",
          material_no: completeData.material_no || "",
          storage_bin: storage_bin_loc || "",
          status: status_qty,
          job_id: jobCardNumber || "",
          type: "SB" || "",
          global_material_no: completeData.global_material_no || "",
          global_material_desc: completeData.global_material_desc || "",
        };

        results.push(result); // Push the result object into the results array
      } else {
        const spId = spBasketId;
        const result = {
          id: spId,
          oem_no: data.oem_no || "",
          oem_description: data.oem_description || "",
          notes: data.notes || "",
          quantity: newQuantity || "",
          uom: data.b_un || "",
          material_no: data.material_no || "",
          storage_bin: data.storage_bin || "",
          status: data.status || 0, // You can set a default value here
          job_id: jobCardNumber || "",
          type: "SB" || "",
          global_material_no: data.global_material_no || "",
          global_material_desc: data.global_material_desc || "",
        };

        results.push(result); // Push the result object into the results array
      }

      if (results.length > 0) {
        // Check if the results array is not empty

        const response = await postSpBasket(results);
        setModalOpen(false);

        if (response.status === 201) {
          handleToastOpen("success", "Data added successfully!");
          fetchSpBasketID();
        }
      } else {
        setModalOpen(false);
        handleToastOpen("error", "Error occurred during API request:");
      }
    } catch (error) {
      setModalOpen(false);
      handleToastOpen("error", "Record already present!");
    } finally {
      setModalOpen(false);
      setIsLoading(false); // Set isLoading to false after try-catch block execution
    }
  };

  //Historical Job :Open Modal for quantity
  const handleOpenModal = (data) => {
    setClickedData(data);
    setQuantity(data.quantity);
    setModalOpen(true);
  };

  //Historical Job :Close Modal
  const handleCloseModal = () => {
    setModalOpen(false);
  };

  //Historical Job : Pagination
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  //Historical Job :Rows Per Page
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  //Historical Job : To view image in carousel
  const handleImageClick = async (imageLink, index) => {
    setClickedImageIndex(index);
    setSliderImages(imageLink);
    setOpenCarousel(true);
  };

  const handleAddImage = (materialNo) => {
    window.location.href = `/warehouse/image-upload?mat_no=${materialNo}`;
  };

  return {
    jobDetails,
    page,
    rowsPerPage,
    modalOpen,
    selectedWorkOrderId,
    clickedImageIndex,
    sliderImages,
    searchQuery,
    onButtonClick,
    handleImageClick,
    handleOpenModal,
    handleChangePage,
    handleChangeRowsPerPage,
    handleAddToCart,
    setSearchQuery,
    handleCloseModal,
    setClickedImageIndex,
    setSelectedWorkOrderId,
    handleQuantityChange,
    error,
    quantity,
    spDetails,
    handleSaveData,
    setOpenCarousel,
    openCarousel,
    handleToastOpen,
    handleAddImage,
    isLoading,
  };
};

export default HistoricalJobDetailsVM;
