import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import SearchIcon from "@mui/icons-material/Search";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import { Link } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  Tooltip,
  IconButton,
  Typography,
  InputAdornment,
  TextField,
} from "@mui/material";
import HistoricalJobVM from "../viewmodel/HistoricalJobVM";
import CDropdown from "../../../../basecomp/components/CDropDown";
import CDateTimePicker from "../../../../basecomp/components/CDateTimePicker";
import CInput from "../../../../basecomp/components/CInput";
import ClearIcon from "@mui/icons-material/Clear";

const HistoricalJobs = () => {
  const {
    dropdownData,
    tableData,
    showTable,
    page,
    rowsPerPage,
    filteredEquipmentIDs,
    filteredEquipmentTypes,
    handleSearchClick,
    handleResetClick,
    setSearchQuery,
    searchQuery,
    handleFromChange,
    handleToChange,
    handleJobIdChange,
    handleJobTypeChange,
    handleCreatedByChange,
    handleEquipmentTypeChange,
    handleEquipmentIdChange,
    handleJobDirectiveChange,
    handleChangePage,
    handleChangeRowsPerPage,
    resetAll,
  } = HistoricalJobVM();

  return (
    <Grid container justifyContent="center">
      <Grid item xs={10} sm={8} md={10} sx={{ margin: "5em 1em 0.5em 1em" }}>
        <Box sx={{ flexGrow: 1, marginTop: "2em" }}>
          <Grid container>
            <Grid item xs={12} sm={12} md={6}>
              <Grid
                xs={12}
                sm={12}
                md={10.6}
                sx={{
                  backgroundColor: "#f2f2f2",
                  padding: "8px 16px",
                  borderRadius: "5px",
                }}
              >
                <Typography>Period Selection</Typography>
              </Grid>

              <Grid container spacing={2} paddingTop={0.9}>
                <Grid item xs={12} sm={12} md={5.3}>
                  <CDateTimePicker
                    resetKey={resetAll}
                    id="hist_from_date_id"
                    cOnChange={handleFromChange}
                    cHelperText="From"
                    hideTime={true}
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={5.3}>
                  <CDateTimePicker
                    resetKey={resetAll}
                    id="hist_to_date_id"
                    cOnChange={handleToChange}
                    cHelperText="To"
                    hideTime={true}
                  />
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={11.9}
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  marginTop: "1em",
                  gap: "10px",
                }}
              >
                <Tooltip title="Search">
                  <IconButton
                    id="job_search"
                    style={{
                      backgroundColor: "#052660",
                      color: "#FFFFFF",
                      borderRadius: 5,
                      padding: "10px",
                    }}
                    onClick={handleSearchClick}
                  >
                    <SearchIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Reset">
                  <IconButton
                    id="job_reset"
                    style={{
                      backgroundColor: "#052660",
                      color: "#FFFFFF",
                      borderRadius: 5,
                      padding: "10px",
                    }}
                    onClick={handleResetClick}
                  >
                    <AutorenewIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={6} paddingTop={0}>
              <Grid
                sx={{
                  backgroundColor: "#f2f2f2",
                  padding: "8px 16px",
                  borderRadius: "5px",
                  marginBottom: ".5em",
                }}
              >
                <Typography>Filters</Typography>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                  <CDropdown
                    key={resetAll}
                    id="job_card_no"
                    cHelperText="Job ID"
                    cData={dropdownData ? dropdownData?.id : []}
                    cOnChange={handleJobIdChange}
                  />
                </Grid>

                <Grid item xs={12} sm={4}>
                  <CDropdown
                    key={resetAll}
                    id="job_type"
                    cHelperText="Job Type"
                    cData={dropdownData ? dropdownData?.job_type : []}
                    cOnChange={handleJobTypeChange}
                  />
                </Grid>

                <Grid item xs={12} sm={4} paddingTop={1}>
                  <CDropdown
                    key={resetAll}
                    id="created_by"
                    cHelperText="Created By"
                    cData={dropdownData ? dropdownData?.created_by : []}
                    cOnChange={handleCreatedByChange}
                  />
                </Grid>

                <Grid item xs={12} sm={4}>
                  <CDropdown
                    key={resetAll}
                    id="equipment_type"
                    cHelperText="Equipment Type"
                    cData={[...new Set(filteredEquipmentTypes)].sort() || []}
                    cOnChange={handleEquipmentTypeChange}
                  />
                </Grid>

                <Grid item xs={12} sm={4} paddingTop={1}>
                  <CDropdown
                    key={resetAll}
                    id="equipment_id"
                    cHelperText="Equipment ID"
                    cData={[...new Set(filteredEquipmentIDs)].sort() || []}
                    cOnChange={handleEquipmentIdChange}
                  />
                </Grid>

                <Grid item xs={12} sm={4} paddingTop={1}>
                  <CInput
                    key={resetAll}
                    id="job_desc"
                    cHintText="Enter Job Directive"
                    cOnChange={handleJobDirectiveChange}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {showTable && (
            <>
              <Grid container marginTop={3}>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={12}
                  style={{ textAlign: "right" }}
                >
                  <TextField
                    variant="outlined"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    placeholder="Search..."
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon fontSize="small" />
                        </InputAdornment>
                      ),
                      endAdornment: searchQuery && (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => setSearchQuery("")}
                            edge="end"
                            size="small"
                          >
                            <ClearIcon fontSize="small" />
                          </IconButton>
                        </InputAdornment>
                      ),
                      style: {
                        height: "40px",
                        width: "310px",
                      },
                    }}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        borderRadius: "4px",
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#ccc",
                      },
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#ccc",
                      },
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#ccc",
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <div
                    style={{
                      overflowX: "auto",
                      marginTop: "1em"
                    }}
                  >
                    <Table id="hist_jobs">
                      <TableHead
                        sx={{
                          backgroundColor: "#052660",
                          fontWeight: "semibold",
                          color: "white",
                          fontSize: 16,
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          "& .MuiTableRow-root": {
                            height: "48px", // Custom header row height
                          },
                          "& .MuiTableCell-root": {
                            padding: "0 16px", // Minimal padding for header cells
                          },
                        }}
                      >
                        <TableRow>
                          <TableCell sx={{ color: "white" }}>Job ID</TableCell>
                          <TableCell sx={{ color: "white" }}>
                            Reference No
                          </TableCell>
                          <TableCell sx={{ color: "white" }}>
                            Job Type
                          </TableCell>
                          <TableCell sx={{ color: "white" }}>
                            Job Directive
                          </TableCell>
                          <TableCell sx={{ color: "white" }}>
                            Equipment Type
                          </TableCell>
                          <TableCell sx={{ color: "white" }}>
                            Equipment ID
                          </TableCell>
                          <TableCell sx={{ color: "white" }}>
                            Created By
                          </TableCell>
                          <TableCell sx={{ color: "white" }}>
                            Date/Time Created
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      {showTable ? (
                        <TableBody
                          sx={{
                            "& .MuiTableRow-root": {
                              height: "40px",
                            },
                            "& .MuiTableCell-root": {
                              padding: "0 16px",
                            },
                          }}
                        >
                          {tableData.length > 0 ? (
                            tableData
                              .filter((data) => {
                                // Convert all column values to lowercase for case-insensitive search
                                const values = Object.values(data).map(
                                  (value) => String(value).toLowerCase()
                                );
                                // Check if any column value contains the search query
                                return values.some((value) =>
                                  value.includes(searchQuery.toLowerCase())
                                );
                              })
                              .slice(
                                page * rowsPerPage,
                                page * rowsPerPage + rowsPerPage
                              )
                              .map((data) => (
                                <TableRow key={data.id}>
                                  <TableCell
                                    sx={{
                                      whiteSpace: "nowrap",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                    }}
                                  >
                                    <Link
                                      to={`/d-catalogue/historical-job-detail?job_id=${data.id}`}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      {data.id}
                                    </Link>
                                  </TableCell>

                                  <TableCell
                                    sx={{
                                      whiteSpace: "nowrap",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                    }}
                                  >
                                    {data.reference_no}
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      whiteSpace: "nowrap",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                    }}
                                  >
                                    {data.job_type}
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      whiteSpace: "nowrap",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                    }}
                                  >
                                    {data.job_directive}
                                  </TableCell>

                                  <TableCell
                                    sx={{
                                      whiteSpace: "nowrap",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                    }}
                                  >
                                    {data.equipment_type}
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      whiteSpace: "nowrap",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                    }}
                                  >
                                    {data.equipment_id}
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      whiteSpace: "nowrap",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                    }}
                                  >
                                    {data.created_by_name}
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      whiteSpace: "nowrap",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                    }}
                                  >
                                    {data.created_at}
                                  </TableCell>
                                </TableRow>
                              ))
                          ) : (
                            <TableRow>
                              <TableCell></TableCell>
                              <TableCell></TableCell>
                              <TableCell></TableCell>
                              <TableCell></TableCell>
                              <TableCell colSpan={8} sx={{textAlign:'start'}}>No data found</TableCell>
                            </TableRow>
                          )}
                        </TableBody>
                      ) : null}
                    </Table>
                  </div>
                  {tableData.length > 0 ? (
                    <div
                      style={{
                        position: "sticky",
                        bottom: 0,
                        backgroundColor: "white",
                        borderTop: "1px solid rgba(224, 224, 224, 1)",
                        marginBottom: "3em"
                      }}
                    >
                      <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={tableData.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </Grid>
              </Grid>
            </>
          )}
        </Box>
      </Grid>
    </Grid>
  );
};

export default HistoricalJobs;
