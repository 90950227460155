import React, { useEffect } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import { Tooltip, IconButton } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import PlaylistAddCheckOutlinedIcon from "@mui/icons-material/PlaylistAddCheckOutlined";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import SearchIcon from "@mui/icons-material/Search";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import SBHeaderVM from "../viewmodel/SBHeaderVM";
import CInput2 from "../../../../basecomp/components/CInput2";
import CDropDown2 from "../../../../basecomp/components/CDropDown2";

const SBHeader = ({
  setIsMaterialSearchUI,
  setIsShoppingBasketUI,
  setIsobDetailsUI,
  setIsReplenishmentUI,
  section,
  jobType,
  resources,
  startDate,
  finishDate,
  workDesc,
  externalRef,
  setExternalRef,
  jobDirective,
  setJobDirective,
  equipmentType,
  setEquipmentType,
  equipmentID,
  setEquipmentID,
  spData,
  setSpData,
  setSpDetails,
  spDetails,
}) => {
  const {
    dropdownData,
    jobCardNumber,
    setJobCardNumber,
    handleSaveJob,
    ShoppingBasket,
    MaterialSearch,
    JobDetails,
    Replenishment,
    filteredEquipmentIDs,
    filteredEquipmentTypes,
    setcatalogueId,
  } = SBHeaderVM({
    startDate,
    finishDate,
    jobDirective,
    equipmentID,
    equipmentType,
    externalRef,
    jobType,
    section,
    resources,
    workDesc,
    setIsMaterialSearchUI,
    setIsShoppingBasketUI,
    setIsobDetailsUI,
    setIsReplenishmentUI,
    spData,
    setSpData,
    setSpDetails,
    spDetails,
  });

  // Retrieve values from session storage when the component mounts
  useEffect(() => {
    const storedEquipmentId = sessionStorage.getItem("sb_equipment_id");
    if (storedEquipmentId) {
      const equipmentData = JSON.parse(storedEquipmentId);
      setEquipmentID(equipmentData);
    }
  }, []);

  return (
    <div>
      <Grid
        container
        spacing={1.5}
        style={{
          paddingLeft: ".8em",
          paddingRight: "2em",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Grid item xs={6} sm={6} md={1.6}>
          <Typography>Job ID</Typography>
          <TextField
            id="job_id"
            size="small"
            style={{ marginTop: "0.5em" }}
            value={jobCardNumber}
            onChange={(event) => setJobCardNumber(event.target.value)}
            disabled
          />
        </Grid>

        <Grid item xs={6} sm={6} md={1.7}>
          <Typography style={{ marginBottom: "0.5em" }}>
            External Ref.
          </Typography>
          <CInput2
            cId="sb_external_ref"
            value={externalRef}
            cOnChange={(value) => setExternalRef(value)}
            cIsSession={true}
            cIsSmall={true}
          />
        </Grid>

        <Grid item xs={6} sm={6} md={3}>
          <Typography style={{ marginBottom: "0.5em" }}>
            Job Directive*
          </Typography>
          <CInput2
            cId="sb_job_directive"
            value={jobDirective}
            cOnChange={(value) => setJobDirective(value)}
            cIsSession={true}
            cIsSmall={true}
            cFullWidth={true}
          />
        </Grid>

        <Grid item xs={6} sm={6} md={2.5}>
          <Typography style={{ marginBottom: "0.5em" }}>
            Equipment Type*
          </Typography>
          <CDropDown2
            cId="sb_equipment_type"
            cHelperText="Choose..."
            cData={[...new Set(filteredEquipmentTypes)].sort()}
            cErrorMsg=""
            cOnChange={(newValue) => setEquipmentType(newValue)}
            cIsSession={true}
            cFullWidth={true}
          />
        </Grid>

        <Grid item xs={6} sm={6} md={2.5}>
          <Typography style={{ marginBottom: "0.5em" }}>
            Equipment ID*
          </Typography>
          <CDropDown2
            cId="sb_equipment_id"
            cHelperText="Choose..."
            cFullWidth={true}
            cData={filteredEquipmentIDs.sort() || []}
            cErrorMsg=""
            cOnChange={(newValue) => {
              setEquipmentID(newValue);
              const selectedCatalogueId = newValue
                ? newValue.catalogue_id
                : null;
              setcatalogueId(selectedCatalogueId);
            }}
            getOptionLabel={(option) =>
              option && option.equipment_number
                ? option.equipment_number
                : equipmentID
                ? equipmentID.equipment_number
                : ""
            }
            isOptionEqualToValue={(option, value) =>
              option.equipment_number === value.equipment_number &&
              option.catalogue_id === value.catalogue_id
            }
            cIsSession={true}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={0.1} style={{ margin: "2em 1.4em 0 0" }}>
          <Tooltip title="Save Job">
            <IconButton
              id="save_job"
              style={{
                backgroundColor: "#052660",
                color: "#FFFFFF",
                borderRadius: 5,
                padding: "10px",
              }}
              onClick={handleSaveJob}
            >
              <SaveIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
      <Grid style={{ padding: 0, margin: "0 0.9em" }}>
        <div
          style={{
            height: "50px",
            display: "flex",
            alignItems: "center",
            backgroundColor: "#E8E8E8",
          }}
        >
          <Tooltip title="Material Search">
            <IconButton
              id="material_search"
              sx={{
                color: "black",
                ml: "8px",
                mr: "15px",
              }}
              onClick={MaterialSearch}
            >
              <SearchIcon />
            </IconButton>
          </Tooltip>

          <Tooltip title="Shopping Basket">
            <IconButton
              id="shopping_basket"
              sx={{
                color: "black",
                mr: "15px",
              }}
              onClick={ShoppingBasket}
            >
              <ShoppingCartOutlinedIcon />
            </IconButton>
          </Tooltip>

          <Tooltip title="Job Details">
            <IconButton
              id="material_list"
              sx={{
                color: "black",
                mr: "15px",
              }}
              onClick={JobDetails}
            >
              <PlaylistAddCheckOutlinedIcon />
            </IconButton>
          </Tooltip>

          <Tooltip title="Replenishment">
            <IconButton
              id="replenishment"
              sx={{
                color: "black",
                mr: "15px",
              }}
              onClick={Replenishment}
            >
              <ErrorOutlineOutlinedIcon />
            </IconButton>
          </Tooltip>
        </div>
      </Grid>
    </div>
  );
};

export default SBHeader;
