import { Grid } from "@mui/material";
import React, { useState } from "react";
import CButton from "../../../basecomp/components/CButton";
import CDateTimePicker from "../../../basecomp/components/CDateTimePicker";
import CTable from "../../../basecomp/components/CTable";
import BinLocationVM from "../viewmodel/BinLocationVM";
import CChooseFile from "../../../basecomp/components/CChooseFile2";
import CBackDrop from "../../../basecomp/components/CBackDrop";
import CText from "../../../basecomp/components/CText";

const BinLocationDetails = () => {
  const {
    downloadExportAllFile,
    getBinLocationDetails,
    binLocationData,
    handleStartDateChange,
    handleEndDateChange,
    loading,
    adminBinUploadAccess,
    handleFileChoose,
    handleBinLocFileUpload,
    handleSampleFileDownload
  } = BinLocationVM();
  const [infoRowData, setInfoRowData] = useState([
    { value: `material_no`, status: false, type: "T" },
    { value: `terminal_id`, status: false, type: "T" },
    { value: `storage_loc`, status: false, type: "T" },
    { value: `storage_bin`, status: false, type: "T" },
    { value: `created_by`, status: false, type: "T" },
    { value: `created_at`, status: false, type: "T" },
    { value: `updated_by`, status: false, type: "T" },
    { value: `updated_at`, status: false, type: "T" },
  ]);

  const infoHeadData = [
    "Material Number",
    "Plant Id",
    "Storage Location",
    "Storage Bin Location",
    "Created By",
    "Created Date/Time",
    "Updated By",
    "Updated Date/Time",
  ];
  return (
    <Grid
      style={{
        marginTop: "6em",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Grid
        style={{
          display: "flex",
          justifyContent: "center",
          gap: "1em",
        }}
      >
        {adminBinUploadAccess && (
          <>
            <CChooseFile
              cFileType="XCL"
              cTooltipText="Upload excel"
              cHelperText="*File Types Excel/CSV"
              cHandleFileChange={handleFileChoose}
              cFullWidth={true}
              cIsRequired={true}
            />

            <Grid
              container
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              gap={1}
              justifyContent="center"
              style={{ paddingBottom: "2.5em" }}
            >
              <CButton
                cText={"Upload"}
                cTooltipText="Upload the Bin location file"
                cOnClick={handleBinLocFileUpload}
              />
              <CButton
                cText="Bin Locations File"
                cTooltipText="Download the sample Bin locations file"
                cOnClick={handleSampleFileDownload}
              />
            </Grid>
          </>
        )}
        <CBackDrop cOpen={loading} />

        <Grid item xs={8} sm={6} md={4} lg={4} xl={4}>
          <CDateTimePicker
            cHelperText="Start Date"
            cOnChange={handleStartDateChange}
            cFullWidth={true}
            hideTime={true}
          />
        </Grid>
        <Grid item xs={8} sm={6} md={4} lg={4} xl={4}>
          <CDateTimePicker
            cHelperText="End Date"
            cOnChange={handleEndDateChange}
            cFullWidth={true}
            hideTime={true}
          />
        </Grid>
        <CButton
          cText={"View"}
          cTooltipText="View the Sap Bin Location File"
          cOnClick={getBinLocationDetails}
        />
        <CButton
          cText={"Download"}
          cTooltipText="Download the Sap Bin Location File"
          cOnClick={downloadExportAllFile}
          cDisabled={binLocationData.length === 0}
        />
      </Grid>
      {binLocationData && binLocationData.length > 0 && (
        <Grid style={{ width: "95%" }}>
          <CTable
            id="label_info_table"
            cHeaderData={infoHeadData}
            cRowData={infoRowData}
            cActualData={binLocationData}
            cIsPagination={true}
            cSmallSize={true}
            cColor="#2196f3"
          />
        </Grid>
      )}
    </Grid>
  );
};

export default BinLocationDetails;
