/* eslint-disable jsx-a11y/alt-text */
import Header from "./Header";
import Footer from "./Footer";
import "../assets/themes.css";
import Warehouse_1 from "../assets/warehouse_1.png";

function New() {
  return (
    <>
      <div style={{ backgroundColor: "#F6F4F9" }}>
        <Header />

        <div
          className="container-xl hero-header"
          style={{ backgroundColor: "#13226c", marginTop: "5em" }}
        >
          <div className="title" style={{ textAlign: "center" }}>
            <h1
              className="text-white animated slideInDown"
              style={{ margin: "0" }}
            >
              New Page
            </h1>

            <hr
              className="thin-line"
              style={{
                width: "100px",
                height: "1px",
                backgroundColor: "#808080",
                border: "none",
              }}
            ></hr>
          </div>
        </div>

        <div className="container-xxl">
          <div className="row align-items-center">
            <div className="col-lg-12 animated1 slideInUp">
              <div
                className="fontclass"
                align="justify"
                style={{ fontSize: "1.3rem", color: "#13226c", margin: "0" }}
              >
                Conas has a lot of experience improving the warehouse management
                function for spare parts within maintenance organizations.
                Warehouse Management encompasses the principles and processes
                involved in running day-to-day operations of a warehouse. Good
                warehouse Management ensures all warehouse processes run as
                efficiently and accurately as possible. To accomplish this,
                Conas worked on improving the following functions:<br></br>
                <br></br>
                <center>
                  <img
                    src={Warehouse_1}
                    style={{
                      maxHeight: "450px",
                      maxWidth: "800px",
                      width: "100%",
                      height: "auto",
                    }}
                  ></img>
                </center>
                <br></br>
                <b>Warehouse Design & Layout</b>
                <br></br>
                We have a lot of experience in designing warehouse operations
                for spare parts. Both greenfield and warehouse extension plans.
                This includes storage location management and developing a
                location numbering system. Our approach is end to end; we start
                with the warehouse design and lay-out and finish by marking the
                warehouse area’s, rows & sections and labeling the locations and
                storage bins. We also make sure that the locations are placed in
                an upload-file for the warehouse management system. The detailed
                design is finalized using Kaizen and implementing 5S.<br></br>
                <br></br>
                <b>LEAN / 5S</b>
                <br></br>
                We support local staff with starting up the new warehouse
                operation by providing training on warehouse management best
                practices and providing hyper care during the 5S -Standardize &
                Sustain phase. During this phase we perform 5S audits, define
                corrective actions and guide the local staff in solving
                warehouse management issues themselves.<br></br>
                <br></br>
                <b>Warehouse Management System</b>
                <br></br>
                We also support our customers implementing their warehouse
                management system or Warehouse Management Module. Our strength
                is our inclusive, end-to-end approach where we focus on all
                issues related to the storage, handling & identification of the
                physical goods, but also the related information; spare part
                attributes, description, where used information, manufacturer
                information, criticality, ABC analyses, Unit Of Measures/ UOM
                conversions & replenishment settings. We developed specialized
                software to easily link all this information and make it easily
                available just by scanning the physical goods. More information
                regarding our software solutions, you find at digital link and
                digital catalogue.Before implementing a Warehouse Management
                System, we assess both Warehouse Management Processes and
                Warehouse Management Data. This can also be part of a Warehouse
                Optimization Project for an existing warehouse operation.
                <br></br>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </>
  );
}

export default New;
