import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import { QRCodeSVG } from "qrcode.react";
import CloudDownloadOutlinedIcon from "@mui/icons-material/CloudDownloadOutlined";
import JobDetailsVM from "../viewmodel/JobDetailsVM";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
import CDropDown2 from "../../../../basecomp/components/CDropDown2";
import CInput2 from "../../../../basecomp/components/CInput2";
import CDateTimePicker from "../../../../basecomp/components/CDateTimePicker";
import CText from "../../../../basecomp/components/CText";

const styles = {
  table: {
    border: "1px solid #d3d3d3",
    borderCollapse: "collapse",
  },
  tableCell: {
    textAlign: "center",
  },
};

const JobDetails = (props) => {
  const {
    spDetails,
    jobDirective,
    equipmentType,
    equipmentID,
    section,
    setSection,
    jobType,
    setJobType,
    resources,
    setResources,
    workDesc,
    setWorkDesc,
    jobCardNumber,
    externalRef,
    startDate,
    finishDate,
    setStartDate,
    setFinishDate,
    handleToastOpen,
  } = props;
  const {
    jobDropdown,
    handleStartDateChange,
    handleFinishDateChange,
    handleIconButtonClick,
    handleButtonDownload,
    isLoading,
  } = JobDetailsVM({
    spDetails,
    jobDirective,
    equipmentType,
    equipmentID,
    jobCardNumber,
    externalRef,
    jobType,
    section,
    resources,
    workDesc,
    startDate,
    finishDate,
    setStartDate,
    setFinishDate,
    handleToastOpen,
  });

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Grid item xs={12} sm={12} md={12} sx={{margin:'1.6em .8em'}}>
        <CText cVariant="header_card" cText="Job Details" />
      </Grid>
      <Grid item xs={12} sm={12} md={12} margin={2}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={2.2}>
            <Typography style={{ marginBottom: "0.5em" }} noWrap>
              Job Type
            </Typography>
            <CDropDown2
              cId="sb_job_type"
              cHelperText="Choose..."
              cData={jobDropdown && jobDropdown.type ? jobDropdown.type : []}
              cErrorMsg=""
              cOnChange={(newValue) => setJobType(newValue)}
              cIsSession={true}
              getOptionLabel={(option) => option || ""}
              isOptionEqualToValue={(option, value) =>
                option === value || option === ""
              }
            />
          </Grid>

          <Grid item xs={12} sm={2.2}>
            <Typography style={{ marginBottom: "0.5em" }} noWrap>
              Section
            </Typography>

            <CDropDown2
              cId="sb_section"
              cHelperText="Choose..."
              cData={
                jobDropdown && jobDropdown.section ? jobDropdown.section : []
              }
              cErrorMsg=""
              cOnChange={(newValue) => setSection(newValue)}
              cIsSession={true}
              getOptionLabel={(option) => option || ""}
              isOptionEqualToValue={(option, value) =>
                option === value || option === ""
              }
            />
          </Grid>

          <Grid item xs={12} sm={1.6}>
            <Typography style={{ marginBottom: "0.5em" }} noWrap>
              Resources
            </Typography>
            <CInput2
              cId="sb_resources"
              cType="number"
              cIsSession={true}
              cIsSmall
              cIsRequired
              cValue={resources === "" ? 1 : Math.max(1, resources)}
              cHtmlProps={{ min: 0 }}
              cOnChange={(value) => {
                const parsedValue = parseInt(value);
                if (!isNaN(parsedValue) && parsedValue >= 0) {
                  setResources(value === "" ? "" : parsedValue);
                }
              }}
            />
          </Grid>

          <Grid item xs={12} sm={3}>
            <Typography style={{ marginBottom: "0.5em" }} noWrap>
              From
            </Typography>
            <CDateTimePicker
              cId="sb_start_date_time"
              cOnChange={handleStartDateChange}
              cIsSession={true}
              value={startDate}
            />
          </Grid>

          <Grid item xs={12} sm={3}>
            <Typography style={{ marginBottom: "0.5em" }} noWrap>
              To
            </Typography>
            <CDateTimePicker
              cId="sb_finish_date_time"
              cOnChange={handleFinishDateChange}
              cIsSession={true}
              value={finishDate}
            />
          </Grid>
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12} paddingTop={1}>
          <Typography style={{ marginBottom: "0.5em" }}>
            Job Description
          </Typography>
          <CInput2
            cId="sb_work_desc"
            cValue={workDesc || ""}
            cOnChange={(value) => setWorkDesc(value)}
            cIsSession={true}
            cFullWidth={true}
            cRows={5}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={12} paddingTop={0.4}>
          <div style={{ overflowX: "auto" }}>
            {spDetails && spDetails.length > 0 ? (
              <Table id="jobdetails">
                <TableHead
                  sx={{
                    backgroundColor: "#052660",
                    "& .MuiTableRow-root": {
                      height: "48px", // Custom header row height
                    },
                    "& .MuiTableCell-root": {
                      padding: "0 16px", // Minimal padding for header cells
                    },
                  }}
                >
                  <TableRow>
                    <TableCell sx={{ color: "#fff", textAlign: "left" }}>
                      Location/Bin
                    </TableCell>
                    <TableCell sx={{ color: "#fff", textAlign: "left" }}>
                      Material
                    </TableCell>
                    <TableCell sx={{ color: "#fff", textAlign: "left" }}>
                      Description
                    </TableCell>
                    <TableCell sx={{ color: "#fff", textAlign: "center" }}>
                      QTY
                    </TableCell>
                    <TableCell sx={{ color: "#fff", textAlign: "center" }}>
                      UOM
                    </TableCell>
                    <TableCell sx={{ color: "#fff", textAlign: "center" }}>
                      OHQ
                    </TableCell>
                    <TableCell sx={{ color: "#fff", textAlign: "center" }}>
                      QR code
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody
                  sx={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    "& .MuiTableRow-root": {
                      height: "45px",
                    },
                    "& .MuiTableCell-root": {
                      padding: "2px 16px",
                    },
                  }}
                >
                  {spDetails
                    .filter((data) => data.material_no)
                    .map((data) => (
                      <TableRow key={data.id}>
                        <TableCell sx={{ textAlign: "left" }}>
                          {data.storage_bin !== "null" ? data.storage_bin : ""}
                        </TableCell>
                        <TableCell sx={{ textAlign: "left" }}>
                          {data.material_no}
                        </TableCell>
                        <TableCell sx={{ textAlign: "left" }}>
                          {data.material_desc}
                        </TableCell>
                        <TableCell sx={styles.tableCell}>
                          {data.quantity}
                        </TableCell>
                        <TableCell sx={styles.tableCell} l>
                          {data.uom}
                        </TableCell>
                        <TableCell
                          sx={{
                            ...styles.tableCell,
                            input: { width: "20px", height: "20px" },
                          }}
                        >
                          <input
                            type="checkbox"
                            defaultChecked={data.status === 1}
                            disabled
                          />
                        </TableCell>

                        <TableCell sx={styles.tableCell}>
                          <QRCodeSVG value={data.material_no} size={40} />
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            ) : null}
          </div>
        </Grid>

        <Grid
          item
          xs={12}
          sm={12}
          md={11.9}
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "1.5em",
            gap: "1em",
          }}
        >
          <Tooltip title="Job Details Email">
            <IconButton
              id="email_job_details"
              style={{
                backgroundColor: "#052660",
                color: "#FFFFFF",
                borderRadius: 5,
                padding: "10px",
              }}
              onClick={handleIconButtonClick}
            >
              <MailOutlineIcon fontSize="small" />
            </IconButton>
          </Tooltip>
          <Tooltip title="Job Details Pdf">
            <IconButton
              id="download_job_details"
              style={{
                backgroundColor: "#052660",
                color: "#FFFFFF",
                borderRadius: 5,
                padding: "10px",
              }}
              onClick={handleButtonDownload}
            >
              <CloudDownloadOutlinedIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
    </>
  );
};

export default JobDetails;
