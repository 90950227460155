import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import headerLogo from "../assets/conas_logo.jpg";
import "../assets/themes.css";
import { useNavigate, useLocation } from "react-router-dom";
import { styled } from "@mui/system";
import CButton from "../../basecomp/components/CButton";
import Avatar from "@mui/material/Avatar";
import Popover from "@mui/material/Popover";
import Grid from "@mui/material/Grid";
import UserUtils from "../../basecomp/auth/UserUtils";

// Define the StyledUserName component
const StyledUserName = styled("div")({
  fontSize: "1.2em", // Change the font size to your desired value
  color: "#13226c", // Change the color to #13226c
  padding: "0.5em",
});

const navItems = [
  { label: "Home", id: "home", path: "/" },
  { label: "Our Services", id: "services", path: "/" },
  { label: "Our Solutions", id: "solutions", path: "/" },
  {
    label: "Industry Verticals",
    id: "IndustryVerticles",
    path: "/IndustryVerticles",
  },
  { label: "About Us", id: "About", path: "/About" },
  { label: "Contact Us", id: "Contact", path: "/Contact" },
];

const StyledLogo = styled("img")({
  maxWidth: "100%",
  maxHeight: "100%",
  height: "2em",
  width: "auto",
  marginLeft: "3em",
});

const StyledPopoverContent = styled("div")({
  padding: "1em",
  background: "#fff",
  "& button": {
    fontSize: "1.2em",
    padding: "0.5em 1em",
    display: "flex",
    alignItems: "center",
    "& .MuiSvgIcon-root": {
      fontSize: "2em",
      marginRight: "0.5em",
    },
  },
});

function Header(props) {
  const { handleLogout, getUserName, getInitials } = UserUtils;
  const [contentVisible, setContentVisible] = useState(false);
  const [hoveredItem, setHoveredItem] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const [activePage, setActivePage] = useState(null);
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [profilePopover, setProfilePopover] = useState(null);

  const handlePopOpen = (event) => {
    setProfilePopover(event.currentTarget);
  };

  const handleClose = () => {
    setProfilePopover(null);
  };

  const userName = "";//getUserName();
  const initials = "";//getInitials();
  const handleLogin = () => {
    navigate("/login"); // Redirect to the "/login" route
  };

  const handleMouseEnter = (item) => {
    setHoveredItem(item);
  };

  const handleMouseLeave = () => {
    setHoveredItem(null);
  };

  const handleClick = (item) => {
    if (item === "Home") {
      setActivePage("Home");
      navigate("/");
      setTimeout(() => {
        const homeSection = document.getElementById("home");
        if (homeSection) {
          homeSection.scrollIntoView({ behavior: "smooth" });
        }
      }, 500);
    } else if (item === "Our Services") {
      setActivePage("Our Services");
      navigate("/");
      setTimeout(() => {
        const servicesSection = document.getElementById("services");
        if (servicesSection) {
          servicesSection.scrollIntoView({ behavior: "smooth" });
        }
      }, 500);
    } else if (item === "Our Solutions") {
      setActivePage("Our Solutions");
      navigate("/");
      setTimeout(() => {
        const solutionsSection = document.getElementById("solutions");
        if (solutionsSection) {
          solutionsSection.scrollIntoView({ behavior: "smooth" });
        }
      }, 500);
    } else if (item === "Industry Verticals") {
      setActivePage("Industry Verticals");
      navigate("/IndustryVerticles");
    } else if (item === "About Us") {
      setActivePage("About Us");
      navigate("/About");
    } else if (item === "Contact Us") {
      setActivePage("Contact Us");
      navigate("/Contact");
    }
  };

  useEffect(() => {
    const currentPath = location.pathname;
    const activeNavItem = navItems.find(
      (navItem) => navItem.path === currentPath
    );
    setActivePage(activeNavItem?.label || null);
  }, [location.pathname]);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 768); // Adjust the breakpoint as needed
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleDrawerToggle = () => {
    setContentVisible(!contentVisible);
  };

  return (
    <>
      <Box sx={{ display: "flex" }}>
        <AppBar
          style={{
            backgroundColor: "white",
            boxShadow: "none",
            minHeight: "50px",
          }}
        >
          <Toolbar style={{ marginLeft: "2.5em", marginRight: "5.5em" }}>
            <div style={{ flex: 1 }}>
              <StyledLogo src={headerLogo} alt="Conas Logo" />
            </div>

            <Box
              sx={{ marginRight: "2em", display: { xs: "block", lg: "none" } }}
            >
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="end"
                onClick={handleDrawerToggle}
              >
                <MenuIcon style={{ color: "black" }} />
              </IconButton>
            </Box>

            <Box
              sx={{ display: { xs: "none", lg: "block" }, marginRight: "4em" }}
            >
              {navItems.map((item) => (
                <a
                  href={`#${item.id}`}
                  key={item.label}
                  style={{
                    textDecoration: "none",
                    marginRight: "10px",
                    color:
                      hoveredItem === item.label || activePage === item.label
                        ? "#64be28"
                        : "black",
                  }}
                  onClick={() => handleClick(item.label)}
                  onMouseEnter={() => handleMouseEnter(item.label)}
                  onMouseLeave={handleMouseLeave}
                >
                  <Typography
                    variant="body1"
                    component="span"
                    sx={{
                      fontSize: "17px",
                      marginRight: "8px",
                      fontFamily: "'Jost', sans-serif",
                      fontWeight: "bold",
                    }}
                  >
                    {item.label}
                  </Typography>
                </a>
              ))}
            </Box>

            {userName ? (
              <>
                <Grid
                  item
                  xs={3}
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginTop: "0.2em",
                  }}
                >
                  <Avatar
                    sx={{
                      padding: "0.2em",
                      bgcolor: "#3CB043",
                      fontWeight: "bold",
                      cursor: "pointer",
                    }}
                    onClick={(e) => handlePopOpen(e)}
                  >
                    {initials}
                  </Avatar>
                </Grid>
                <Popover
                  style={{ marginTop: "0.5em" }}
                  open={Boolean(profilePopover)}
                  anchorEl={profilePopover}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                >
                  <StyledPopoverContent
                    style={{ textAlign: "center", width: "200px" }}
                  >
                    <StyledUserName style={{ width: "100%" }}>
                      {userName}
                    </StyledUserName>

                    <CButton
                      id="login_out"
                      cTooltipText="LogOut"
                      cOnClick={handleLogout}
                      CFullWidth={true}
                      cText="LogOut"
                    />
                  </StyledPopoverContent>
                </Popover>
              </>
            ) : (
              <CButton
                id="login"
                cTooltipText="Login"
                cOnClick={handleLogin}
                cText="Login"
              />
            )}
          </Toolbar>
        </AppBar>

        {contentVisible && (
          <div style={{ marginTop: "5em" }}>
            <a href="#home" onClick={() => handleClick("Home")}>
              Home
            </a>
            <br></br>
            <a href="#services" onClick={() => handleClick("Our Services")}>
              Our Services
            </a>
            <br></br>
            <a href="#solutions" onClick={() => handleClick("Our Solutions")}>
              Our Solutions
            </a>
            <br></br>
            <a
              href="IndustryVerticles#IndustryVerticles"
              onClick={() => handleClick("Industry Verticals")}
            >
              Industry Verticals
            </a>
            <br></br>
            <a href="About#About" onClick={() => handleClick("About Us")}>
              About Us
            </a>
            <br></br>
            <a href="Contact#Contact" onClick={() => handleClick("Contact Us")}>
              Contact Us
            </a>
          </div>
        )}
      </Box>
    </>
  );
}

Header.propTypes = {
  window: PropTypes.func,
};

export default Header;
