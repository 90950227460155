import React, { useState } from "react";
import { Grid, Card, Typography, FormGroup, Tooltip } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";

const CCustomCard = ({
  title,
  data,
  filterType,
  handleCheckBtnClick,
  selectedItems = {},
  onResetFilter,
}) => {
  // State for search input
  const [searchTerm, setSearchTerm] = useState("");

  const handleCheckboxChange = (itemId) => {
    // Create a new object with the updated selection
    const updatedSelectedItems = {
      ...selectedItems,
      [filterType]: {
        ...selectedItems[filterType],
        [itemId]: !selectedItems[filterType]?.[itemId],
      },
    };

    handleCheckBtnClick(updatedSelectedItems);
  };

  // New reset handler
  const handleResetFilter = () => {
    // Create a copy of selectedItems and remove all selections for this filter type
    const updatedSelectedItems = { ...selectedItems };
    delete updatedSelectedItems[filterType];

    onResetFilter(updatedSelectedItems);
  };

  // Handle search input change
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  // Clear search input
  const handleClearSearch = () => {
    setSearchTerm("");
  };

  // Filter data based on search term
  const filteredData = data.filter((item) =>
    item.description.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <Grid item xs={12} sm={12} md={2.4}>
      <Card
        style={{
          height: "22em",
          padding: "1em",
          position: "relative",
          marginBottom: "2em",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            height: "2em",
            color: "black",
            position: "relative",
            zIndex: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: ".2em .5em",
            textWrap: "nowrap",
          }}
        >
          <Typography>{title}</Typography>
          <Tooltip title="Clear filter">
            <DeleteIcon
              onClick={handleResetFilter}
              style={{
                cursor: "pointer",
                color: "gray",
                marginLeft: "8px",
                fontSize: "1em",
              }}
            />
          </Tooltip>
        </div>

        {/* Search Input with Inline Clear Icon */}
        <div
          style={{
            position: "relative",
            padding: "0 1.5em 0em 0.5em",
            marginBottom: "0.5em",
          }}
        >
          <SearchIcon
            onClick={handleClearSearch}
            style={{
              position: "absolute",
              left: "1em",
              top: "50%",
              transform: "translateY(-50%)",
              cursor: "pointer",
              color: "gray",
              fontSize: "15px",
            }}
          />
          <input
            type="text"
            placeholder="Search..."
            value={searchTerm}
            onChange={handleSearchChange}
            style={{
              width: "100%",
              padding: "0.5em",
              paddingLeft: "2em",
              borderRadius: "5px",
              border: "1px solid #ccc",
              outline: "none",
            }}
          />
          {searchTerm && (
            <ClearIcon
              onClick={handleClearSearch}
              style={{
                position: "absolute",
                right: "0em",
                top: "50%",
                transform: "translateY(-50%)",
                cursor: "pointer",
                color: "gray",
                fontSize: "17px",
              }}
            />
          )}
        </div>

        <div
          style={{
            marginTop: "1em",
            height: "calc(100% - 5.5em)",
            overflow: "auto",
          }}
        >
          <FormGroup>
            {filteredData.map((item) => (
              <div
                key={item.id}
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "0.5em",
                }}
              >
                <input
                  type="checkbox"
                  checked={selectedItems[filterType]?.[item.id] || false}
                  onChange={() => handleCheckboxChange(item.id)}
                  style={{ marginLeft: "1em" }}
                />
                <Typography
                  variant="body2"
                  style={{ fontSize: "12px", marginLeft: "0.5em" }}
                  onClick={(e) => e.stopPropagation()}
                >
                  {item.description}
                </Typography>
              </div>
            ))}
          </FormGroup>
        </div>
      </Card>
    </Grid>
  );
};

export default CCustomCard;
