/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { useToast } from "../../../../basecomp/components/provider/ToastProvider";
import {
  getDataForCheckBox,
  getSQ01ByID,
  getSQ01AndSpBasket,
  getGlobalAndSpBasket,
  postSpBasket,
  getSpBasketID,
  getFilterData,
  handlePgrFiler,
} from "../model/MaterialSearchModel";

const MaterialSearchVM = (props) => {
  const {
    setSpDetails,
    jobCardNumber,
    setSpData,
    lclMaterialNo,
    setlclMaterialNo,
    spData,
  } = props;
  const [showTable, setShowTable] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [spBasketId, setSpBasketId] = useState("");
  const [material, setMaterial] = useState("");
  const [globalMaterial, setGlobalMaterial] = useState("");
  const [materialDesc, setMaterialDesc] = useState("");
  const [manufName, setManufName] = useState("");
  const [manufNo, setManufNo] = useState("");
  const [equipFunc, setEquipFunc] = useState("");
  const [binloc, setBinLoc] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [quantity, setQuantity] = useState(1);
  const [completeData, setCompleteData] = useState(null);
  const [openCarousel, setOpenCarousel] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { handleToastOpen } = useToast();
  const [searchQuery, setSearchQuery] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  // const [selectedRowId, setSelectedRowId] = useState(null);
  const [sliderImages, setSliderImages] = useState([]);
  const [pgrData, setDataPgr] = useState([]);
  const [levelOneData, setLevelOneData] = useState([]);
  const [levelTwoData, setLevelTwoData] = useState([]);
  const [levelThreeData, setLevelThreeData] = useState([]);
  const [levelFourData, setLevelFourData] = useState([]);
  const [selectedItems, setSelectedItems] = useState({});
  const [currentSelectedRowId, setCurrentSelectedRowId] = useState(null);
  const [rowStatuses, setRowStatuses] = useState({});
  const [successfulItems, setSuccessfulItems] = useState([]);

  // Function to determine row background color
  const getRowBackgroundColor = (data) => {
    if (successfulItems.includes(data.id)) {
      return "#b3ffb3"; 
    }
    if (data.id === currentSelectedRowId) {
      return "#ffe0b2"; 
    }
    return "inherit";
  };
  
  const handleRowSelection = (rowId) => {
    setCurrentSelectedRowId(rowId);
  };

  // Initial all Data
  const fetchSQ01Dropdown = async () => {
    try {
      setIsLoading(true);
      const globalData = await getDataForCheckBox();
      const result = globalData?.data;
      if (result) {
        setDataPgr(result.pgr || []);
        setLevelOneData(result.level1 || []);
        setLevelTwoData(result.level2 || []);
        setLevelThreeData(result.level3 || []);
        setLevelFourData(result.level4 || []);
      } else {
        console.error("No result found");
      }
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setIsLoading(false);
    }
  };

  // Checkbox button selection and state update
  const handleCheckBtnClick = async (newSelectedItems) => {
    try {
      setIsLoading(true);
      setSelectedItems(newSelectedItems);

      const urlParams = Object.entries(newSelectedItems)
        .map(([key, value]) => {
          const selectedIds = Object.entries(value)
            .filter(([_, isSelected]) => isSelected)
            .map(([id]) => id);
          return selectedIds.length ? `${key}=${selectedIds.join(",")}` : "";
        })
        .filter(Boolean)
        .join("&");

      const filterData = await handlePgrFiler(urlParams);

      const result = filterData?.data;
      if (result) {
        setDataPgr(result.pgr || []);
        setLevelOneData(result.level1 || []);
        setLevelTwoData(result.level2 || []);
        setLevelThreeData(result.level3 || []);
        setLevelFourData(result.level4 || []);
      } else {
        console.error("No result found");
      }
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setIsLoading(false);
    }
  };

  // handle filter reset
  const handleResetFilter = async (updatedSelectedItems) => {
    try {
      setIsLoading(true);
      setSelectedItems(updatedSelectedItems);

      const urlParams = Object.entries(updatedSelectedItems)
        .map(([key, value]) => {
          const selectedIds = Object.entries(value)
            .filter(([_, isSelected]) => isSelected)
            .map(([id]) => id);
          return selectedIds.length ? `${key}=${selectedIds.join(",")}` : "";
        })
        .filter(Boolean)
        .join("&");

      const filterData = await handlePgrFiler(urlParams);

      const result = filterData?.data;
      if (result) {
        setDataPgr(result.pgr || []);
        setLevelOneData(result.level1 || []);
        setLevelTwoData(result.level2 || []);
        setLevelThreeData(result.level3 || []);
        setLevelFourData(result.level4 || []);
      } else {
        console.error("No result found");
      }
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setIsLoading(false);
    }
  };

  // handle to clear all filtering
  const handleResetAllFilter = async () => {
    setSelectedItems({});
    fetchSQ01Dropdown();
    setTableData([]);
    setShowTable(false);
  };

  useEffect(() => {
    fetchSQ01Dropdown();
  }, []);

  //Material Search : To view image in carousel
  const handleImageClick = async (imageLink, index) => {
    setSliderImages(imageLink);
    setOpenCarousel(true);
  };

  //Material Search : Check quantity must be numeric and update new quantity
  const handleChange = (e) => {
    const inputValue = e.target.value;

    // Numeric regex pattern
    const numericPattern = /^[0-9]*$/;

    if (numericPattern.test(inputValue)) {
      setQuantity(inputValue);
    } else {
      setQuantity("");
    }
  };

  //Material Search : Get row data for add to cart by its Id (SQ01)
  const fetchRowData = async (id) => {
    try {
      const data = await getSQ01ByID(id);
      return data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  //D-Catalogue
  const fetchSpBasketAll = async () => {
    try {
      const data = await getSQ01AndSpBasket();
      if (data === undefined || (data && data.error === "Data not found")) {
        setSpDetails([]);
      } else {
        const updatedData = data.map((obj) => ({
          ...obj,
          tableMode: "T",
        }));
        setSpDetails(updatedData);
      }
    } catch (error) {
      console.error("Error fetching SP basket details:", error);
    }
  };

  const fetchSpDataAll = async () => {
    try {
      const data = await getGlobalAndSpBasket();

      if (data === undefined || (data && data.error === "Data not found")) {
        setSpData([]);
      } else {
        const updatedData = data.map((obj) => ({
          ...obj,
          tableMode: "T",
        }));
        setSpData(updatedData);
      }
    } catch (error) {
      console.error("Error fetching SP basket details:", error);
    }
  };

  //Material Search : Generate new sp_basket id logic (Job Id Catalogue)
  const fetchSpBasketID = async () => {
    try {
      const response = await getSpBasketID();
      const data = await response.data;

      if (data) {
        setSpBasketId((prevSpBasketID) => {
          return data.sp_basket_id;
        });
      } else {
        console.error(data.error);
      }
    } catch (error) {
      console.error("Error occurred during API request:", error);
    }
  };

  useEffect(() => {
    fetchSpBasketID();
  }, []);

  //Material Search : Insert or Add data in sp_basket (Sp Basket)
  const addToCart = async () => {
    setModalOpen(false);
    setIsLoading(true);
    if (quantity <= 0) {
      handleToastOpen("error", "Quantity must be greater than 0");
      return; 
    }
    const qty_val = completeData[0].ohqty - quantity;
    const status_qty = qty_val >= 0 ? 1 : 0;
    const storage_bin_loc =
      (completeData[0].storage_loc !== null
        ? completeData[0].storage_loc
        : "") +
      (completeData[0].bin_location === null
        ? ""
        : `${completeData[0].storage_loc === null ? "" : " / "}` +
          completeData[0].bin_location);

    const data = [
      {
        id: spBasketId,
        oem_no: "",
        oem_description: "",
        notes: "",
        quantity: quantity || "",
        uom: completeData[0].b_un || "",
        material_no: completeData[0].material_no || "",
        global_material_no: "",
        global_material_desc: "",
        storage_bin: storage_bin_loc || "",
        status: status_qty,
        job_id: jobCardNumber,
        type: "SB" || "",
      },
    ];

    try {
      const response = await postSpBasket(data);
      if (response) {
        setModalOpen(false);
        setSuccessfulItems((prev) => {
          if (!prev.includes(currentSelectedRowId)) {
            return [...prev, currentSelectedRowId];
          }
          return prev;
        });
        handleToastOpen("success", "Data added successfully!");
      } else {
        handleToastOpen("error", "Record already present!");
        setModalOpen(false);
        setQuantity(1);
      }
      fetchSpBasketAll();
      fetchSpDataAll();
      fetchSpBasketID();
    } catch (error) {
      setQuantity(1);
    } finally {
      setIsLoading(false);
    }
  };

  //Material Search : Filter table on search click
  const handleButtonClick = async () => {
    const filterTrueKeys = (items) => {
      const result = {};
      Object.keys(items).forEach((key) => {
        const trueKeys = Object.entries(items[key])
          .filter(([_, value]) => value)
          .map(([subKey]) => Number(subKey));

        if (trueKeys.length > 0) {
          result[key] = trueKeys;
        }
      });
      return result;
    };
    const data = filterTrueKeys(selectedItems);
    const dataMap = {
      pgr_id: pgrData,
      level1_id: levelOneData,
      level2_id: levelTwoData,
      level3_id: levelThreeData,
      level4_id: levelFourData,
    };

    const getDescription = (selectedItems, dataMap) => {
      const result = {};

      for (const [key, ids] of Object.entries(selectedItems)) {
        if (dataMap[key]) {
          const descriptions = ids
            .map(
              (id) => dataMap[key].find((item) => item.id === id)?.description
            )
            .filter(Boolean);
          if (descriptions.length > 0) {
            result[key.replace("_id", "_description")] = descriptions;
          }
        }
      }
      return result;
    };
    const response = getDescription(data, dataMap);
    if (
      material.trim() === "" &&
      globalMaterial.trim() === "" &&
      materialDesc.trim() === "" &&
      manufName.trim() === "" &&
      manufNo.trim() === "" &&
      equipFunc.trim() === "" &&
      binloc.trim() === "" &&
      Object.keys(response).length === 0
    ) {
      setTableData([]);
      setShowTable(false);
      handleToastOpen("error", "Please select any filter");
      return;
    }
    setIsLoading(true);
    const filterData = {
      material: material.trim(),
      globalMaterial: globalMaterial.trim(),
      materialDesc: materialDesc.trim(),
      manufName: manufName.trim(),
      manufNo: manufNo.trim(),
      equipFunc: equipFunc.trim(),
      binloc: binloc.trim(),
      dataCheckBox: response?.pgr_description ?? [],
      data1CheckBox: response?.level1_description ?? [],
      data2CheckBox: response?.level2_description ?? [],
      data3CheckBox: response?.level3_description ?? [],
      data4CheckBox: response?.level4_description ?? [],
    };

    const filteredData = await getFilterData(filterData);
    setIsLoading(false);
    if (filteredData) {
      setTableData(filteredData);
      setShowTable(true);
    } else {
      setTableData([]);
      setShowTable(false);
      handleToastOpen("error", "Data not found!");
    }
  };

  //GS1
  useEffect(() => {
    if (lclMaterialNo) {
      setMaterial(lclMaterialNo);
      handleButtonClick();
    }
  }, [lclMaterialNo, handleButtonClick]);
  const handleOpenModal = async (id) => {
    try {
      if (rowStatuses[id] === "success") return;
      setRowStatuses((prev) => ({
        ...prev,
        [id]: "selected",
      }));
      setCurrentSelectedRowId(id);
      // setSelectedRowId(id);
      const completeData = await fetchRowData(id);
      setCompleteData(completeData);
      setModalOpen(true);
    } catch (error) {
      console.error(error);
    }
  };

  const handleAddImage = (materialNo) => {
    const url = `/warehouse/image-upload?mat_no=${materialNo}`;
    window.open(url, "_blank");
  };

  const clearSelection = async () => {
    setModalOpen(false);
  };

  return {
    showTable,
    tableData,
    modalOpen,
    setModalOpen,
    handleChange,
    addToCart,
    handleButtonClick,
    material,
    setMaterial,
    globalMaterial,
    setGlobalMaterial,
    materialDesc,
    setMaterialDesc,
    manufName,
    setManufName,
    manufNo,
    setManufNo,
    equipFunc,
    setEquipFunc,
    binloc,
    setBinLoc,
    setSearchQuery,
    searchQuery,
    setOpenCarousel,
    openCarousel,
    isLoading,
    clearSelection,
    quantity,
    pgrData,
    levelOneData,
    levelTwoData,
    levelThreeData,
    levelFourData,
    handleCheckBtnClick,
    selectedItems,
    handleResetFilter,
    handleResetAllFilter,
    setPage,
    setRowsPerPage,
    page,
    rowsPerPage,
    handleAddImage,
    handleOpenModal,
    handleImageClick,
    sliderImages,
    getRowBackgroundColor,
    handleRowSelection,
    successfulItems
  };
};

export default MaterialSearchVM;
