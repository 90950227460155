/* eslint-disable jsx-a11y/alt-text */
import Header from "./Header";
import Footer from "./Footer";
import "../assets/themes.css";
import MDM from "../assets/MDM.png";

function MDMPage() {
  return (
    <>
      <div style={{ backgroundColor: "#F6F4F9" }}>
        <Header />

        <div
          className="container-xl hero-header"
          style={{ backgroundColor: "#13226c", marginTop: "5em" }}
        >
          <div className="title" style={{ textAlign: "center" }}>
            <h1
              className="text-white animated slideInDown"
              style={{ margin: "0" }}
            >
              Master Data Management
            </h1>

            <hr
              className="thin-line"
              style={{
                width: "100px",
                height: "1px",
                backgroundColor: "#808080",
                border: "none",
              }}
            ></hr>
          </div>
        </div>

        <div className="container-xxl" style={{ marginBottom: "-2em" }}>
          <div className="row align-items-center">
            <div className="col-lg-12 animated1 slideInUp">
              <div
                className="fontclass"
                align="justify"
                style={{ fontSize: "1.3rem", color: "#13226c", margin: "0" }}
              >
                <b>MDM Services:</b>
                <br></br> <br></br>
                Managing data for thousands of different products, materials,
                suppliers, customers, and chart of accounts for multiple plants
                for an enterprise is a huge task. Different entities end up
                creating their own master data with their locally built
                standards which leads to incomplete, consistent master data,
                duplicate- or faulty -records. Therefore, it is necessary to
                have a centralized standard procedure to maintain the master
                data of the enterprise across the globe. CONAS not only helps
                cleaning the existing master data but also makes sure the new
                data getting inside your ERP is clean. Master data management
                will act as building blocks for your organization digital
                transformation. This in turn will help your organization to
                transform into a data driven organization, fueling your
                organization growth. No matter what ERP or what CRM tools are
                used, structured data, precise analytics and robust reporting
                will make the business processes efficient.
                <br></br>
                <br></br>
                <center>
                  <img
                    src={MDM}
                    style={{
                      maxHeight: "450px",
                      maxWidth: "800px",
                      width: "100%",
                      height: "auto",
                    }}
                  ></img>
                </center>
                <br></br>
                <b style={{ fontSize: "1.7rem" }}>
                  We support below master data streams:
                </b>
                <br></br>
                <ul
                  type="square"
                  className="mb-4"
                  align="justify"
                  style={{ fontSize: "1.3rem" }}
                >
                  <li>Spare part/ material master data.</li>
                  <li>Inventory / Product master data.</li>
                  <li>Supplier/ Vendor master data.</li>
                  <li>Customer master data.</li>
                  <li>Employee master data.</li>
                  <li>Chart of accounts.</li>
                  <li>Resource data management.</li>
                  <li>Asset data management.</li>
                  <li>Location data management.</li>
                </ul>
                <p
                  className="mb-4"
                  align="justify"
                  style={{ fontSize: "1.3rem" }}
                >
                  Our expertise lies in strategizing and addressing all the data
                  transformations by taking lift and shift approach or lift
                  clean and shift approach at the following levels:
                </p>
                <ul
                  type="square"
                  className="mb-4"
                  align="justify"
                  style={{ fontSize: "1.3rem" }}
                >
                  <li> Master data</li>
                  <li> Unstructured data</li>
                  <li> Transactional data</li>
                  <li> Meta data</li>
                  <li> Hierarchical data</li>
                  <li> Reference and attribute data</li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </>
  );
}

export default MDMPage;
