import React, { useEffect, useRef } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import CInput from "./CInput";

const CDropDown = ({ 
  cHelperText, 
  cData = [], 
  cErrorMsg, 
  cOnChange,
  cIsOptionName,
  cDefaultValue,
  cIsRequired,
  cFullWidth = true 
}) => {
  const [selectedValue, setSelectedValue] = React.useState(cDefaultValue || "");
  const resetRef = useRef(false);
  const initialSelectionMade = useRef(false);

  useEffect(() => {
    // Only auto-select for size dropdown when there's exactly one option
    if (
      cData?.length === 1 && 
      !initialSelectionMade.current && 
      !selectedValue &&
      cIsOptionName === "size"  // Only auto-select for size dropdown
    ) {
      setSelectedValue(cData[0]);
      cOnChange(cData[0]);
      initialSelectionMade.current = true;
    }
  }, [cData, cIsOptionName, cOnChange, selectedValue]);

  useEffect(() => {
    if (resetRef.current) {
      setSelectedValue("");
      resetRef.current = false;
      initialSelectionMade.current = false;
    }
  }, [resetRef]);

  const handleChange = (event, newValue) => {
    setSelectedValue(newValue);
    cOnChange(newValue);
    initialSelectionMade.current = true;
  };

  const resetDropdown = () => {
    resetRef.current = true;
  };

  const getOptionLabel = (option) => {
    if (!option) return "";
    
    // Handle size dropdown specifically
    if (cIsOptionName === "size") {
      return option?.size || "";
    }
    
    // Handle other dropdowns
    return cIsOptionName ? option[cIsOptionName]?.toString() : option.toString();
  };

  return (
    <Autocomplete
      options={cData}
      onChange={handleChange}
      getOptionLabel={getOptionLabel}
      value={selectedValue}
      renderInput={(params) => (
        <CInput
          cHintText={cHelperText}
          cDropDown={params}
          cErrorMsg={cErrorMsg}
          cIsRequired={cIsRequired}
        />
      )}
      fullWidth={cFullWidth}
    />
  );
};

export default CDropDown;