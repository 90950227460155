/* eslint-disable no-unused-vars */
import { useEffect, useState } from "react";
import { ThemeProvider } from "@mui/system";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import TextField from "@mui/material/TextField";
import InfoIcon from "@mui/icons-material/Info";
import SearchIcon from "@mui/icons-material/Search";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import MaterialSearchVM from "../viewmodel/MaterialSearchVM";
import CCarousel from "../../../../basecomp/components/CCarousel";
import CBackDrop from "../../../../basecomp/components/CBackDrop";
import CText from "../../../../basecomp/components/CText";
import CCustomCard from "../../../../basecomp/components/CCustomCard";
import PreviewIcon from "@mui/icons-material/Preview";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import {
  AppBar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Toolbar,
  Tooltip,
} from "@mui/material";

const MaterialSearch = (props) => {
  const {
    setSpDetails,
    setSpData,
    setLightboxOpen,
    jobCardNumber,
    handleToastOpen,
    lclMaterialNo,
    setlclMaterialNo,
    spData,
  } = props;
  const {
    showTable,
    tableData,
    modalOpen,
    setModalOpen,
    handleChange,
    addToCart,
    handleButtonClick,
    material,
    setMaterial,
    globalMaterial,
    setGlobalMaterial,
    materialDesc,
    setMaterialDesc,
    manufName,
    setManufName,
    manufNo,
    setManufNo,
    equipFunc,
    setEquipFunc,
    binloc,
    setBinLoc,
    setSearchQuery,
    searchQuery,
    setOpenCarousel,
    openCarousel,
    isLoading,
    clearSelection,
    quantity,
    pgrData,
    levelOneData,
    levelTwoData,
    levelThreeData,
    levelFourData,
    handleCheckBtnClick,
    selectedItems,
    handleResetFilter,
    handleResetAllFilter,
    setPage,
    setRowsPerPage,
    page,
    rowsPerPage,
    handleAddImage,
    handleOpenModal,
    handleImageClick,
    sliderImages,
    getRowBackgroundColor,
    handleRowSelection,
    successfulItems,
  } = MaterialSearchVM({
    setSpDetails,
    setLightboxOpen,
    jobCardNumber,
    setSpData,
    handleToastOpen,
    lclMaterialNo,
    setlclMaterialNo,
    spData,
  });

  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    const filtered = tableData.filter((data) =>
      Object.entries(data).some(([key, value]) => {
        const excludedKeys = ["id", "created_at", "image_links"];
        if (excludedKeys.includes(key)) return false;
        if (value === null || value === undefined) return false;
        const searchValue = searchQuery.toLowerCase();
        const stringValue = String(value).toLowerCase();
        return stringValue.includes(searchValue);
      })
    );

    setFilteredData(filtered);
    setPage(0);
  }, [searchQuery, tableData]);

  return (
    <>
      <>
        <CBackDrop cOpen={isLoading} />
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          sx={{ margin: "1.5em 0.9em 0.5em 0.9em" }}
        >
          <CText cVariant="header_card" cText="Material Search" />
        </Grid>

        <Grid item xs={12} sm={12} md={12} sx={{ margin: "0 .9em" }}>
          <Grid container spacing={1.5}>
            <CCustomCard
              title="Purchasing Group"
              data={pgrData}
              filterType="pgr_id"
              handleCheckBtnClick={handleCheckBtnClick}
              selectedItems={selectedItems}
              onResetFilter={handleResetFilter}
            />
            <CCustomCard
              title="Taxonomy Level 1"
              data={levelOneData}
              filterType="level1_id"
              handleCheckBtnClick={handleCheckBtnClick}
              selectedItems={selectedItems}
              onResetFilter={handleResetFilter}
            />
            <CCustomCard
              title="Taxonomy Level 2"
              data={levelTwoData}
              filterType="level2_id"
              handleCheckBtnClick={handleCheckBtnClick}
              selectedItems={selectedItems}
              onResetFilter={handleResetFilter}
            />
            <CCustomCard
              title="Taxonomy Level 3"
              data={levelThreeData}
              filterType="level3_id"
              handleCheckBtnClick={handleCheckBtnClick}
              selectedItems={selectedItems}
              onResetFilter={handleResetFilter}
            />
            <CCustomCard
              title="Taxonomy Level 4"
              data={levelFourData}
              filterType="level4_id"
              handleCheckBtnClick={handleCheckBtnClick}
              selectedItems={selectedItems}
              onResetFilter={handleResetFilter}
            />
          </Grid>
        </Grid>

        <Grid item xs={12} sm={12} md={12} sx={{ margin: "0 .9rem" }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={3}>
              <div style={{ marginTop: 4 }}>
                <Typography noWrap>Local Material Number</Typography>
                <TextField
                  size="small"
                  id="job_directive"
                  style={{ marginTop: "0.55em", width: "100%" }}
                  label="Enter value"
                  value={lclMaterialNo !== "" ? lclMaterialNo : material}
                  onChange={(e) => {
                    if (lclMaterialNo !== "") {
                      setlclMaterialNo(e.target.value);
                    } else {
                      setMaterial(e.target.value);
                    }
                  }}
                />
              </div>
            </Grid>

            <Grid item xs={12} sm={3}>
              <div style={{ marginTop: 4 }}>
                <Typography noWrap>Global Material Number</Typography>
                <TextField
                  size="small"
                  id="job_directive"
                  style={{ marginTop: "0.55em", width: "100%" }}
                  label="Enter value"
                  value={globalMaterial}
                  onChange={(e) => setGlobalMaterial(e.target.value)}
                />
              </div>
            </Grid>

            <Grid item xs={12} sm={6}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <Typography variant="body1" noWrap>
                  Material Description
                </Typography>
                <ThemeProvider>
                  <Tooltip
                    title="Enter any part of the description. In case of multiple
                        parts (search strings), use a % sign as separator
                        between the parts. For example: gear%hoist or bolt%M%12"
                    placement="right"
                  >
                    <span style={{ marginLeft: 5 }}>
                      <InfoIcon
                        style={{
                          color: "#052660",
                          marginTop: 2,
                          fontSize: "20px",
                          cursor: "pointer",
                        }}
                      />
                    </span>
                  </Tooltip>
                </ThemeProvider>
              </div>
              <TextField
                label="Enter value"
                size="small"
                style={{ marginTop: "0.5em", width: "100%" }}
                value={materialDesc}
                onChange={(e) => setMaterialDesc(e.target.value)}
              />
            </Grid>

            <Grid item xs={12} sm={3}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <Typography variant="body1" noWrap>
                  Manufacture Name
                </Typography>
                <ThemeProvider>
                  <Tooltip
                    title="Enter any part of the Manufacturer. In case of multiple
                        parts (search strings), use a % sign as separator
                        between the parts. For Example kal, kalmar%bromma"
                    placement="right"
                  >
                    <span style={{ marginLeft: 5 }}>
                      <InfoIcon
                        style={{
                          color: "#052660",
                          marginTop: 2,
                          fontSize: "20px",
                          cursor: "pointer",
                        }}
                      />
                    </span>
                  </Tooltip>
                </ThemeProvider>
              </div>
              <TextField
                size="small"
                id="job_directive"
                style={{ marginTop: "0.5em", width: "100%" }}
                label="Enter value"
                value={manufName}
                onChange={(e) => setManufName(e.target.value)}
              />
            </Grid>

            <Grid item xs={12} sm={3}>
              <div style={{ marginTop: 4 }}>
                <Typography noWrap>Manufacture Part Number</Typography>
                <TextField
                  size="small"
                  id="job_directive"
                  style={{ marginTop: "0.55em", width: "100%" }}
                  label="Enter value"
                  value={manufNo}
                  onChange={(e) => setManufNo(e.target.value)}
                />
              </div>
            </Grid>

            <Grid item xs={12} sm={3}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <Typography variant="body1" noWrap>
                  Equipment Function
                </Typography>
                <ThemeProvider>
                  <Tooltip
                    title="Enter any part of the Equipment Function. In case of
                        multiple parts (search strings), use a % sign as
                        separator between the parts. For E.g. qc, qc%RTG"
                    placement="right"
                  >
                    <span style={{ marginLeft: 5 }}>
                      <InfoIcon
                        style={{
                          color: "#052660",
                          marginTop: 2,
                          fontSize: "20px",
                          cursor: "pointer",
                        }}
                      />
                    </span>
                  </Tooltip>
                </ThemeProvider>
              </div>
              <TextField
                size="small"
                id="job_directive"
                style={{ marginTop: "0.5em", width: "100%" }}
                label="Enter value"
                value={equipFunc}
                onChange={(e) => setEquipFunc(e.target.value)}
              />
            </Grid>

            <Grid item xs={12} sm={3}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <Typography variant="body1" noWrap>
                  Bin Location
                </Typography>
                <ThemeProvider>
                  <Tooltip
                    title="Enter any part of the Bin Location. In case of multiple
                        parts (search strings), use a % sign as separator
                        between the parts. For E.g. WF%01"
                    placement="right"
                  >
                    <span style={{ marginLeft: 5 }}>
                      <InfoIcon
                        style={{
                          color: "#052660",
                          marginTop: 2,
                          fontSize: "20px",
                          cursor: "pointer",
                        }}
                      />
                    </span>
                  </Tooltip>
                </ThemeProvider>
              </div>
              <TextField
                size="small"
                id="job_directive"
                style={{ marginTop: "0.5em", width: "100%" }}
                label="Enter value"
                value={binloc}
                onChange={(e) => setBinLoc(e.target.value)}
              />
            </Grid>
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={11.9}
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              marginTop: "1.5em",
              gap: "10px",
            }}
          >
            <Tooltip title="Search">
              <IconButton
                id="d-cat-search"
                style={{
                  backgroundColor: "#052660",
                  color: "#FFFFFF",
                  borderRadius: 5,
                  padding: "10px",
                }}
                onClick={handleButtonClick}
              >
                <SearchIcon fontSize="small" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Reset">
              <IconButton
                id="d-cat-reset"
                style={{
                  backgroundColor: "#052660",
                  color: "#FFFFFF",
                  borderRadius: 5,
                  padding: "10px",
                }}
                onClick={handleResetAllFilter}
              >
                <AutorenewIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>

        {showTable && (
          <>
            <Grid container sx={{ marginTop: "0.05rem" }}>
              <Grid item xs={12} sm={12} md={12} sx={{ margin: "0.5em .9em" }}>
                <CText cVariant="header_card" cText="Search Details" />
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                md={12}
                style={{
                  textAlign: "right",
                  paddingRight: "0.5em",
                  margin: "0 .4em 1.5em 0em",
                }}
              >
                <TextField
                  variant="outlined"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  placeholder="Search..."
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon fontSize="small" />
                      </InputAdornment>
                    ),
                    endAdornment: searchQuery && (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => setSearchQuery("")}
                          edge="end"
                          size="small"
                        >
                          <ClearIcon fontSize="small" />
                        </IconButton>
                      </InputAdornment>
                    ),
                    style: {
                      height: "40px",
                      width: "310px",
                    },
                  }}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "4px",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#ccc",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#ccc",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#ccc",
                    },
                  }}
                />
              </Grid>

              {showTable && tableData.length > 0 && (
                <Grid item xs={8} sm={8} md={12}>
                  <div style={{ overflowX: "auto", margin: "0 .9em" }}>
                    <Table>
                      <TableHead
                        sx={{
                          backgroundColor: "#052660",
                          fontWeight: "semibold",
                          color: "white",
                          fontSize: 16,
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          "& .MuiTableRow-root": {
                            height: "48px", // Custom header row height
                          },
                          "& .MuiTableCell-root": {
                            padding: "0 16px", // Minimal padding for header cells
                          },
                        }}
                      >
                        <TableRow>
                          <TableCell>
                            <IconButton sx={{ color: "white" }}>
                              <ShoppingCartOutlinedIcon
                                sx={{ fontSize: "22px" }}
                              />
                            </IconButton>
                          </TableCell>
                          <TableCell>
                            <IconButton sx={{ color: "white" }}>
                              <CameraAltIcon sx={{ fontSize: "21px" }} />
                            </IconButton>
                          </TableCell>
                          <TableCell sx={{ color: "white" }}>
                            Material
                          </TableCell>
                          <TableCell
                            style={{ width: "150px" }}
                            sx={{ color: "white" }}
                          >
                            Description
                          </TableCell>
                          <TableCell
                            style={{ width: "150px" }}
                            sx={{ color: "white" }}
                          >
                            Local Material Description
                          </TableCell>
                          <TableCell sx={{ color: "white" }}>GMN</TableCell>
                          <TableCell
                            style={{ width: "150px" }}
                            sx={{ color: "white" }}
                          >
                            Gbl Material Desc.
                          </TableCell>
                          <TableCell sx={{ color: "white" }}>
                            Equipment Function
                          </TableCell>
                          <TableCell sx={{ color: "white" }}>
                            Bin Location
                          </TableCell>
                          <TableCell sx={{ color: "white" }}>OHQ</TableCell>
                          <TableCell sx={{ color: "white" }}>UOM</TableCell>
                          <TableCell sx={{ color: "white" }}>
                            Manufacturer Info
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody
                        sx={{
                          "& .MuiTableRow-root": {
                            height: "40px",
                          },
                          "& .MuiTableCell-root": {
                            padding: "0 16px",
                          },
                        }}
                      >
                        {showTable && filteredData.length > 0 ? (
                          filteredData
                            .slice(page * rowsPerPage, (page + 1) * rowsPerPage)
                            .map((data) => {
                              const isSuccessful = successfulItems.includes(
                                data.id
                              );
                              return (
                                <TableRow
                                  key={data.id}
                                  style={{
                                    backgroundColor:
                                      getRowBackgroundColor(data),
                                  }}
                                  onClick={() => handleRowSelection(data.id)}
                                >
                                  <TableCell>
                                    {isSuccessful ? (
                                      <IconButton color="success">
                                        <CheckIcon
                                          sx={{
                                            fontSize: "22px",
                                            color: "#052660",
                                          }}
                                        />
                                      </IconButton>
                                    ) : (
                                      <Tooltip title="Add to cart">
                                        <IconButton
                                          color="#052660"
                                          onClick={() =>
                                            handleOpenModal(data.id)
                                          }
                                        >
                                          <ShoppingCartOutlinedIcon
                                            sx={{
                                              fontSize: "22px",
                                              color: "#052660",
                                            }}
                                          />
                                        </IconButton>
                                      </Tooltip>
                                    )}
                                  </TableCell>
                                  <TableCell>
                                    {data.image_links.length === 0 ||
                                    data.image_links === undefined ? (
                                      <Tooltip title="Upload Image">
                                        <IconButton
                                          id="add_image"
                                          onClick={() =>
                                            handleAddImage(data.material_no)
                                          }
                                        >
                                          <AddAPhotoIcon
                                            sx={{
                                              fontSize: "22px",
                                              color: "#052660",
                                            }}
                                          />
                                        </IconButton>
                                      </Tooltip>
                                    ) : (
                                      <Tooltip title="View Image">
                                        <IconButton
                                          id="display_image"
                                          onClick={() =>
                                            handleImageClick(data.image_links)
                                          }
                                        >
                                          <PreviewIcon
                                            sx={{
                                              fontSize: "24px",
                                              color: "#052660",
                                            }}
                                          />
                                        </IconButton>
                                      </Tooltip>
                                    )}
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      whiteSpace: "nowrap",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                    }}
                                  >
                                    {data.material_no}
                                  </TableCell>
                                  <TableCell
                                    style={{ width: "150px" }}
                                    sx={{
                                      whiteSpace: "nowrap",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                    }}
                                  >
                                    {data.material_desc}
                                  </TableCell>
                                  <TableCell
                                    style={{ width: "150px" }}
                                    sx={{
                                      whiteSpace: "nowrap",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                    }}
                                  >
                                    {data.local_material_desc}
                                  </TableCell>
                                  <TableCell>
                                    {data.industry_std_desc}
                                  </TableCell>
                                  <TableCell
                                    style={{ width: "150px" }}
                                    sx={{
                                      whiteSpace: "nowrap",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                    }}
                                  >
                                    {data.long_desc}
                                  </TableCell>
                                  <TableCell>{data.equipment_func}</TableCell>
                                  <TableCell>{data.bin_location}</TableCell>
                                  <TableCell>{data.ohqty}</TableCell>
                                  <TableCell>{data.b_un}</TableCell>
                                  <TableCell>
                                    {(data.mpn_name1 ||
                                      data.manufac_item_code) && (
                                      <div
                                        style={{
                                          whiteSpace: "nowrap",
                                          overflow: "hidden",
                                          textOverflow: "ellipsis",
                                        }}
                                      >
                                        {data.mpn_name1 &&
                                        data.manufac_item_code ? (
                                          <div>
                                            {data.mpn_name1} -{" "}
                                            {data.manufac_item_code}
                                          </div>
                                        ) : (
                                          data.mpn_name1 ||
                                          data.manufac_item_code
                                        )}
                                      </div>
                                    )}

                                    {(data.mpn_name2 || data.mpn_number1) && (
                                      <div
                                        style={{
                                          whiteSpace: "nowrap",
                                          overflow: "hidden",
                                          textOverflow: "ellipsis",
                                        }}
                                      >
                                        {data.mpn_name2 && data.mpn_number1 ? (
                                          <div>
                                            {data.mpn_name2} -{" "}
                                            {data.mpn_number1}
                                          </div>
                                        ) : (
                                          data.mpn_name2 || data.mpn_number1
                                        )}
                                      </div>
                                    )}

                                    {(data.mpn_name3 || data.mpn_number2) && (
                                      <div
                                        style={{
                                          whiteSpace: "nowrap",
                                          overflow: "hidden",
                                          textOverflow: "ellipsis",
                                        }}
                                      >
                                        {data.mpn_name3 && data.mpn_number2 ? (
                                          <div>
                                            {data.mpn_name3} -{" "}
                                            {data.mpn_number2}
                                          </div>
                                        ) : (
                                          data.mpn_name3 || data.mpn_number2
                                        )}
                                      </div>
                                    )}

                                    {(data.mpn_name4 || data.mpn_number3) && (
                                      <div
                                        style={{
                                          whiteSpace: "nowrap",
                                          overflow: "hidden",
                                          textOverflow: "ellipsis",
                                        }}
                                      >
                                        {data.mpn_name4 && data.mpn_number3 ? (
                                          <div>
                                            {data.mpn_name4} -{" "}
                                            {data.mpn_number3}
                                          </div>
                                        ) : (
                                          data.mpn_name4 || data.mpn_number3
                                        )}
                                      </div>
                                    )}
                                  </TableCell>
                                </TableRow>
                              );
                            })
                        ) : (
                          <TableRow>
                            <TableCell colSpan={10} align="center">
                              No data found
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </div>
                  {showTable && filteredData.length > 0 ?(<div
                    style={{
                      position: "sticky",
                      bottom: 0,
                      backgroundColor: "white",
                      borderTop: "1px solid rgba(224, 224, 224, 1)",
                    }}
                  >
                    <TablePagination
                      rowsPerPageOptions={[10, 25, 50, 100]}
                      component="div"
                      count={filteredData.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={(event, newPage) => setPage(newPage)}
                      onRowsPerPageChange={(event) => {
                        const newRowsPerPage = parseInt(event.target.value, 10);
                        setRowsPerPage(newRowsPerPage);
                        setPage(0);
                      }}
                    />
                  </div>):""}
                </Grid>
              )}
            </Grid>
          </>
        )}

        {openCarousel && (
          <CCarousel
            cOpen={openCarousel}
            images={sliderImages}
            cClose={() => setOpenCarousel(false)}
          />
        )}
        <Dialog
          open={modalOpen}
          onClose={() => setModalOpen(false)}
          PaperProps={{
            style: {
              marginBottom: "25em",
              maxWidth: "23em",
              width: "100%",
            },
          }}
        >
          <AppBar
            position="static"
            style={{
              backgroundColor: "#63b144",
              alignItems: "center",
            }}
          >
            <Toolbar>
              <ShoppingCartOutlinedIcon />
            </Toolbar>
          </AppBar>

          <DialogContent>
            <div className="modal-body">
              <Typography
                style={{
                  color: "#1976d2",
                  marginBottom: "1em",
                }}
              >
                QTY
              </Typography>
              <TextField
                id="standard-basic"
                fullWidth
                value={quantity}
                onChange={handleChange}
                variant="standard"
              />
            </div>
          </DialogContent>

          <DialogActions>
            <Button style={{ color: "#1976d2" }} onClick={addToCart}>
              OK
            </Button>
            <Button onClick={clearSelection} style={{ color: "#1976d2" }}>
              CANCEL
            </Button>
          </DialogActions>
        </Dialog>
      </>
    </>
  );
};

export default MaterialSearch;
