import React, { useState } from "react";
import { TextField, Tooltip, Box, Typography } from "@mui/material";

const CChooseFile = ({
  cFileType = "XCL",
  cTooltipText = "Upload a file",
  cHelperText = "",
  cHandleFileChange,
  cFullWidth = false,
  cIsRequired = false,
}) => {
  const [errorMessage, setErrorMessage] = useState("");

  const handleChange = (event) => {
    setErrorMessage("");
    const files = event.target.files;

    if (!files || files.length === 0) {
      setErrorMessage("No file selected. Please choose a file.");
      return;
    }

    const allowedExtensions =
      cFileType === "XCL" ? ["csv", "xlsx"] : cFileType.split(",");
    const invalidFiles = Array.from(files).filter((file) => {
      const fileExtension = file.name.split(".").pop().toLowerCase();
      return !allowedExtensions.includes(fileExtension) || file.size === 0;
    });

    if (invalidFiles.length > 0) {
      const invalidFileNames = invalidFiles.map((file) => file.name).join(", ");
      setErrorMessage(
        `Invalid files detected: ${invalidFileNames}. Allowed file types: ${allowedExtensions.join(
          ", "
        )}. Files should not be empty.`
      );
      event.target.value = "";
      return;
    }

    if (cHandleFileChange) {
      cHandleFileChange(Array.from(files)); // Pass the full files array
    }
  };

  return (
    <Box sx={{ position: "relative", width: cFullWidth ? "100%" : "auto" }}>
      <Tooltip title={cTooltipText}>
        <TextField
          type="file"
          inputProps={{
            accept: cFileType === "XCL" ? ".csv, .xlsx" : cFileType,
            multiple: true, // Allow multiple file selection
          }}
          onChange={handleChange}
          variant="outlined"
          fullWidth={cFullWidth}
          required={cIsRequired}
          error={!!errorMessage}
        />
      </Tooltip>
      {cHelperText && (
        <Typography
          variant="body2"
          sx={{ mt: 1, color: errorMessage ? "error.main" : "text.secondary" }}
        >
          {errorMessage || cHelperText}
        </Typography>
      )}
    </Box>
  );
};

export default CChooseFile;
