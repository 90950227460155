import AxiosWrapper from "../../../../basecomp/base/AxiosWrapper";
import Logger from "../../../../basecomp/base/Logger";

const Base_URL = process.env.REACT_APP_BE_URL;
const {
  axiosGetCallWithToken
} = AxiosWrapper;

const HistoricalJobModel = () => {
  // Historical Job : Get all data from job_id_catalogue_table by terminal_id
  const fetchDataAll = async () => {
    try {
      const response = await axiosGetCallWithToken(
        `${Base_URL}/dcatalogue/getJobIdCatalogue`
      );
      return response.data;
    } catch (error) {
      Logger.printStackTrace(error);
      return [];
    }
  };

  //  Historical Job : To get values in dropdown in historical jobs page(job_card_no, job_type, created_by, job_directive)
  const fetchDropdownData = async () => {
    try {
      const url = `${Base_URL}/dcatalogue/getJobIdCatForDropdown`;
      const response = await axiosGetCallWithToken(url);
      return response.data.data;
    } catch (error) {
      Logger.printStackTrace(error);
      return [];
    }
  };

  //Historical Job : Get unique equipment_id and equipment_number for shopping basket dropdown
  const getEquipmentForDropdown = async () => {
    try {
      const url = `${Base_URL}/dcatalogue/getEquipmentForDropdown`;
      const response = await axiosGetCallWithToken(url);
      return response;
    } catch (error) {
      Logger.messageStackTrace("Error fetching equipment for dropdown:", error);
    }
  };

  return {
    fetchDataAll,
    fetchDropdownData,
    getEquipmentForDropdown,
  };
};

export default HistoricalJobModel;
