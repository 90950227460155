/* eslint-disable jsx-a11y/alt-text */
import Header from "./Header";
import Footer from "./Footer";
import "../assets/themes.css";
import FM from "../assets/f.png";

function FuelManagementPage() {
  return (
    <>
      <div style={{ backgroundColor: "#F6F4F9" }}>
        <Header />

        <div
          className="container-xl hero-header"
          style={{ backgroundColor: "#13226c", marginTop: "5em" }}
        >
          <div className="title" style={{ textAlign: "center" }}>
            <h1
              className="text-white animated slideInDown"
              style={{ margin: "0" }}
            >
              Fuel Management
            </h1>

            <hr
              className="thin-line"
              style={{
                width: "100px",
                height: "1px",
                backgroundColor: "#808080",
                border: "none",
              }}
            ></hr>
          </div>
        </div>

        <div className="container-xxl" style={{ marginBottom: "-2em" }}>
          <div className="row align-items-center">
            <div className="col-lg-12 animated1 slideInUp">
              <div
                className="fontclass"
                align="justify"
                style={{ fontSize: "1.3rem", color: "#13226c", margin: "0" }}
              >
                Fuel-management system is used to maintain, control and monitor
                fuel consumption and stock in any type of industry that uses
                transport, including rail, road, water, and air, as a means of
                business. Fuel-management system is designed to effectively
                measure and manage the use of fuel across various industries.
                <br></br>
                <br></br>
                <b style={{ fontSize: "1.7rem" }}>
                  We provide Fuel efficiency in following ways:
                </b>
                <ul
                  align="justify"
                  style={{ fontSize: "1.3rem" }}
                  type="square"
                >
                  <li>
                    Provide end to end automated entry for fuel used in
                    vehicles.
                  </li>
                  <li>
                    By this tool you can analyze data if the fuel is used
                    efficiently or not.
                  </li>
                  <li>
                    The tool can help you in exporting data in power bi to
                    analyze the fuel consumption of every vehicle/equipment.
                  </li>
                  <br></br>
                  <br></br>
                  <center>
                    <img
                      src={FM}
                      style={{
                        maxHeight: "450px",
                        maxWidth: "900px",
                        width: "100%",
                        height: "auto",
                      }}
                    ></img>
                  </center>
                  <br></br>
                  <b style={{ fontSize: "1.7rem" }}>Advantages:</b>
                  <br></br>
                  <br></br>
                  <li>Can reduce/cutoff fuel thefts.</li>
                  <li>Can save fuel and money efficiently.</li>
                  <li>
                    Can save capital and resources for adding values to your
                    business.
                  </li>
                  <li>
                    Can cut off manual entry which will reduce time and
                    error.For our clients, who are industry leaders, it is not
                    about the next big thing, it's simply everything that's
                    next. The next experience. The next innovation. The next
                    possibility.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </>
  );
}

export default FuelManagementPage;
