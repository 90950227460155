import React, { useEffect, useState } from "react";
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Card from "@mui/material/Card";
import WarehouseLocationVm from "../viewmodel/WarehouseLocationVm";
import CBackDrop from "../../../basecomp/components/CBackDrop";
import { Box, styled } from "@mui/system";
import CloseIcon from "@mui/icons-material/Close";
import WarningIcon from "@mui/icons-material/Warning";
import { useNavigate } from "react-router-dom";
const commonFontSize = ".9em";

const OrderBox = styled("div")({
  textAlign: "start",
  border: "1px solid #3CB043",
  width: "100%",
  margin: "2rem 0em",
  borderRadius: "1.2em 1.2em 1em 1em",
});

const OrderBoxText = styled("div")({
  textAlign: "center",
  backgroundColor: "#3CB043",
  color: "white",
  padding: ".5em 3em",
  borderRadius: "0.7em 0.7em 0em 0em",
  fontSize: "1.2em",
});

const WareHouseView = () => {
  const {
    wareHouseMaterialData,
    infoMatHeadData,
    terminal_id,
    storage_loc,
    loading,
    storage_bin,
    gsOneToolRedirectMethod,
    popUpBox
  } = WarehouseLocationVm();

  const [assetData, setAssetData] = useState([]);
  const [infoRowData, setInfoRowData] = useState([]);
  const navigate = useNavigate();
  const handleClose = () => {
    navigate("/services");
  };
  useEffect(() => {
    setAssetData([
      [
        {
          name: "Plant ID",
          value: terminal_id,
        },
      ],
      [
        {
          name: "Storage Location",
          value: storage_loc,
        },
      ],
      [
        {
          name: "Storage Bin Group",
          value: storage_bin,
        },
      ],
    ]);
    setInfoRowData([
      { value: `name`, type: "T" },
      { value: `value`, type: "T" },
    ]);
  }, [terminal_id, storage_loc, storage_bin]);

  return (
    <Grid
      item
      xs={10}
      sm={10}
      md={10}
      lg={10}
      xl={10}
      sx={{ margin: "5em 0.5em 0.5em 0.5em" }}
    >
                  {popUpBox ? (
          <div
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "#fff",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              zIndex: 1000,
            }}
          >
            <div
              style={{
                position: "relative",
                backgroundColor: "#fff",
                borderRadius: "20px",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                width: "80%",
                maxWidth: "400px",
                textAlign: "center",
              }}
            >
              <button
                onClick={handleClose}
                style={{
                  position: "absolute",
                  top: "20px",
                  right: "10px",
                  background: "none",
                  border: "none",
                  cursor: "pointer",
                }}
              >
                <CloseIcon style={{ fontSize: "35px" }} />
              </button>

              <WarningIcon
                style={{
                  fontSize: "70px",
                  color: "red",
                  margin: "60px 30px 0",
                }}
              />
              <p
                style={{
                  fontSize: "22px",
                  color: "#333",
                  lineHeight: "35px",
                  fontWeight: "300",
                  padding: "0 10px",
                }}
              >
                You are not authorized to view the details of this asset
              </p>

              {/* Close Button */}
              <button
                onClick={handleClose}
                style={{
                  background: "none",
                  border: "none",
                  width: "100%",
                  fontSize: "20px",
                  cursor: "pointer",
                  height: "50px",
                  backgroundColor: "red",
                  color: "#fff",
                  borderRadius: "0 0 10px 10px",
                  marginTop: "20px", // Space between text and button
                }}
              >
                Close
              </button>
            </div>
          </div>
        ) : null}
      <CBackDrop cOpen={loading} />
      <Card sx={{ padding: "0.5em" }}>
        <OrderBox>
          <OrderBoxText>General Info</OrderBoxText>
          <TableContainer>
            <Table size="small">
              <TableBody>
                {assetData?.map((actualArr, rowIndex) => (
                  <TableRow key={rowIndex}>
                    {infoRowData.map((rowData, cellIndex) => (
                      <TableCell
                        component="th"
                        scope="row"
                        key={cellIndex}
                        sx={{
                          padding: "0em 0.2em 0em 2em",
                          margin: "0em",
                          border: "none",
                          fontWeight: "bold",
                          whiteSpace: "nowrap",
                          width: cellIndex === 0 ? "40%" : "60%",
                        }}
                      >
                        {actualArr?.map((actualRow, rowIndex) => (
                          <TableRow key={rowIndex}>
                            <TableCell
                              component="th"
                              scope="row"
                              sx={{
                                textAlign: "left",
                                padding: "0.8em 0em 0.8em 0em",
                                verticalAlign: "middle",
                                fontSize: "1em",
                                border: "none",
                                whiteSpace: "nowrap",
                              }}
                            >
                              {rowData.type === "T" && actualRow[rowData.value]}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </OrderBox>

        <OrderBoxText>Inventory Location Details</OrderBoxText>
        <Box
          sx={{
            border: "1px solid #3CB043", 
            borderRadius: "0 0 1em 1em",
            overflowX: "auto", 
            whiteSpace: "nowrap", 
            marginBottom: "3em", 
            scrollbarWidth: "none",
            "&::-webkit-scrollbar": {
              display: "none",
            },
          }}
        >
          <Table sx={{ minWidth: "650px" }}>
            <TableHead>
              <TableRow>
                {infoMatHeadData.map((header, index) => (
                  <TableCell
                    key={index}
                    sx={{
                      fontWeight: "bold",
                      padding: "6px 24px",
                      fontSize: commonFontSize,
                    }}
                  >
                    {header}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {wareHouseMaterialData?.map((row, rowIndex) => (
                <TableRow key={rowIndex}>
                  {Object.keys(row).map((colKey, colIndex) => (
                    <TableCell
                      key={colIndex}
                      sx={{ padding: "8px 24px", fontSize: commonFontSize }}
                    >
                      {colKey === "material_no" ? (
                        <button
                          onClick={() =>
                            gsOneToolRedirectMethod(row.material_no)
                          } 
                          style={{
                            background: "none",
                            border: "none",
                            color: "blue",
                            textDecoration: "underline",
                            cursor: "pointer",
                            padding: "0",
                            fontSize: "inherit",
                            fontFamily: "inherit",
                          }}
                        >
                          {row[colKey]}
                        </button>
                      ) : (
                        row[colKey]
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
              {wareHouseMaterialData?.length === 0 && (
                <TableRow>
                  <TableCell
                    colSpan={wareHouseMaterialData.length}
                    sx={{ textAlign: "center" }}
                  >
                    No data present
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </Box>
      </Card>
    </Grid>
  );
};

export default WareHouseView;
